import i18n from './i18n.js';
var _ = i18n('repositoryNavigator');

export const dateToString = function(date) {
    return relativeDate(date, new Date());
};

// see jquery.comments.js for this function
export const relativeDate = function(olderDate, newerDate) {
    if (typeof olderDate === "string") {
        olderDate = new Date(olderDate);
    }
    if (typeof newerDate === "string") {
        newerDate = new Date(newerDate);
    }

    var monthNames = [_("Jan"), _("Feb"), _("Mar"), _("Apr"), _("May"), _("June"), _("July"), _("Aug"), _("Sept"), _("Oct"), _("Nov"), _("Dec")];

    var conversions = [
        ["year", 31518720000],
        ["month", 2626560000], // assumes there are 30.4 days in a month
        ["day", 86400000],
        ["hour", 3600000],
        ["minute", 60000]
    ];

    var milliseconds = newerDate - olderDate;

    if (Math.floor(milliseconds / 2626560000) > 0) { // if > 1 month, display the date
        return monthNames[olderDate.getMonth()] + ' ' + olderDate.getDate() + ' ' + olderDate.getFullYear();
    }

    for (var i = 0; i < conversions.length; i++) {
        var result = Math.floor(milliseconds / conversions[i][1]);
        if (result > 0) {
            return _('%d ' + conversions[i][0] + ' ago', {
                count: result,
                postProcess: 'sprintf',
                sprintf: [result]
            });
        }
    }

    return _("Just Now");
};

export const dateToFullString = function(date) {
    if (typeof date === "string") {
        date = new Date(date);
    }
    var monthNames = [_("Jan"), _("Feb"), _("Mar"), _("Apr"), _("May"), _("June"), _("July"), _("Aug"), _("Sept"), _("Oct"), _("Nov"), _("Dec")];
    return monthNames[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear() + ' ' + date.toLocaleTimeString();
};

/*
 * Function that change the width of the columns
 */
export const layoutColumns = function(ul, additionnalPadding) {
    if (typeof additionnalPadding === "undefined") {
        additionnalPadding = 20;
    }
    // calculate the max width of each column
    var columnsCount = ul.find('li.header > .details > span').length;
    var columnMaxWidth = [];
    var i;
    var leftPadding;
    for (i = 0; i < columnsCount; i++) {
        columnMaxWidth[i] = 0;
    }
    var noResizableColumns = [];
    ul.find('li.header > .details > span').each(function(index, element) {
        if ($(element).hasClass('no-resizable')) {
            noResizableColumns.push(index);
        }
    });
    ul.find('li:not(.saveAs)').each(function(index, element) {
        element = $(element);
        element.find('> .details > span').each(function(index, column) {
            columnMaxWidth[index] = Math.max($(column).outerWidth(), columnMaxWidth[index]);
        });
    });

    // add right column margin
    for (i = 0; i < columnsCount; i++) {
        columnMaxWidth[i] = Math.ceil(columnMaxWidth[i] + 2);
    }
    var ulWidth = ul.width() - additionnalPadding - (2 * columnsCount);

    // change the column max-width (for ellipsis) and position

    var diff = ulWidth;
    for (i = 0; i < columnsCount; i++) {
        diff = diff - columnMaxWidth[i];
    }
    var columns = [];
    for (i = 0; i < columnsCount; i++) {
        columns[i] = ul.find(':not(.saveAs) .details > span:nth-child(' + (i + 1) + ')');
    }
    if (diff > 0) { // everything fit
        diff = Math.ceil(diff / columnsCount) - 1;
        leftPadding = 0;
        for (i = 0; i < columnsCount; i++) {
            if (leftPadding !== 0) {
                columns[i].css('left', leftPadding);
            }

            if (noResizableColumns.indexOf(i) > -1) {
                columns[i].css('max-width', columnMaxWidth[i]);
                leftPadding += columnMaxWidth[i];
            } else {
                columns[i].css('max-width', columnMaxWidth[i] + diff);
                leftPadding += columnMaxWidth[i] + diff;
            }
        }
    } else { //Find all column that are smaller than average to redistribute their extra width to column needing it
        var averageColumnWidth = ulWidth / columnsCount;
        var extraWidthAvailable = 0;
        for (i = 0; i < columnsCount; i++) {
            if (columnMaxWidth[i] <= averageColumnWidth) {
                extraWidthAvailable += averageColumnWidth - columnMaxWidth[i];
            }
        }
        //Redistribute width
        for (i = 0; i < columnsCount; i++) {
            if (columnMaxWidth[i] > averageColumnWidth) {
                if (extraWidthAvailable > 0) {
                    if (averageColumnWidth + extraWidthAvailable >= columnMaxWidth[i]) { //By distributing the the extra width we can have all width
                        extraWidthAvailable -= columnMaxWidth[i] - averageColumnWidth;
                    } else { //We can only partially fill the column width requirements
                        columnMaxWidth[i] = averageColumnWidth + extraWidthAvailable;
                        extraWidthAvailable = 0;
                    }
                } else { //We have to tune down the column width to the average
                    columnMaxWidth[i] = averageColumnWidth;
                }
            }
        }
        //Make width integers
        for (i = 0; i < columnsCount; i++) {
            columnMaxWidth[i] = Math.ceil(columnMaxWidth[i]);
        }

        leftPadding = 0;
        for (i = 0; i < columnsCount; i++) {
            columns[i].css('max-width', columnMaxWidth[i]);
            if (leftPadding !== 0) {
                columns[i].css('left', leftPadding);
            }
            leftPadding += columnMaxWidth[i];
        }
    }
};
