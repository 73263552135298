(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@trisotech/issue-editor"), require("i18next"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("REPOSITORY", ["@trisotech/issue-editor", "i18next", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["REPOSITORY"] = factory(require("@trisotech/issue-editor"), require("i18next"), require("jquery"));
	else
		root["REPOSITORY"] = factory(root["@trisotech/issue-editor"], root["i18next"], root["jQuery"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__239__, __WEBPACK_EXTERNAL_MODULE__575__, __WEBPACK_EXTERNAL_MODULE__914__) {
return 