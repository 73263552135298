/* global __webpack_public_path__*/
/* eslint-disable no-global-assign */
export const providerMap = {
    'publicapi': {
        className: 'BPIRepositoryProvider',
        fileName: 'repository.publicapi.js'
    },
    'http': {
        className: 'HTTPRepositoryProvider',
        fileName: 'repository.http.js'
    }
};

class ProviderRegistry {
    constructor() {
        this.providers = [];
        this.newProviders = [];
        this.ready = true;
        this.newReady = true;
    }

    register(config, options, relativePath, success, force) {
        this.ready = false;
        this._addOptions(config, options);
        this._getProviderInstance(config, force).then(provider => {
            this.providers.push(provider);
            this.ready = true;
            success(provider);
        });
    }

    registerProviderInstance(provider) {
        this.providers.push(provider);
    }

    unregister(repository) {
        var index = this.providers.indexOf(repository);
        if (index > -1) {
            this.providers.splice(index, 1);
        }
    }

    get(descriptor, callback) {
        var get = function() {
            for (var i = 0; i < this.providers.length; i++) {
                if (this.providers[i].canHandle(descriptor)) {
                    return this.providers[i];
                }
            }
            return null;
        }.bind(this);
        if (typeof callback === "function") {
            var providerTimeout = setInterval(function() {
                if (this.ready) {
                    clearInterval(providerTimeout);
                    callback(get());
                }
            }.bind(this), 100);
        } else {
            return get();
        }
    }

    getAll(callback) {
        if (typeof callback === "function") {
            var providerTimeout = setInterval(function() {
                if (this.ready) {
                    clearInterval(providerTimeout);
                    callback(this.providers.slice());
                }
            }.bind(this), 100);
        } else {
            return this.providers.slice();
        }
    }

    getAllNew(callback) {
        if (typeof callback === "function") {
            var providerTimeout = setInterval(function() {
                if (this.newReady) {
                    clearInterval(providerTimeout);
                    callback(this.newProviders.slice());
                }
            }.bind(this), 100);
        } else {
            return this.newProviders.slice();
        }
    }

    /**
     * Load provider js file and return the provider class
     */
    async _getProvider(config, relativePath) {
        // validate if valid configuration
        if (!providerMap[config.type]) {
            throw new Error("Invalid provider configuration.");
        }
        let {className, fileName} = providerMap[config.type];

        let providerModule = await import(
            /* webpackInclude: /repository\..*\.js$/ */
            /* webpackChunkName: "providers/[request]" */
            /* webpackMode: "lazy" */
            `./providers/${fileName}`);

        return providerModule[className];
    }

    async _getProviders(configs) {
        let promises = configs.map(config => {
            return (async () => {
                return {
                    ...config,
                    provider: await this._getProvider(config)
                };
            })();
        });
        return Promise.all(promises);
    }

    /**
     * Create an instance of a provider with a defined configuration
     * @param  {[type]} config provider configuration
     * @param  {[type]} force  enforce the dynamically instantiable configuration
     * @return {[type]}        an instance of a provider
     */
    async _getProviderInstance(config, force) {
        let provider = await this._getProvider(config);
        let providerInstance = new provider(config.params);
        if (!providerInstance.dynamicallyInstantiable && !force) {
            throw new Error("Provider not dynamically instanciable.");
        }

        return providerInstance;
    }

    _getRelativePath(relativePath) {
        if (!relativePath.endsWith('/')) {
            relativePath += '/';
        }
        /*
         * Because the webpack packaging have a public path relative to js/providers/ (see output configuration),
         * we should navigate to the parent to ensure backware compatibility
         */
        return relativePath + '../';
    }

    /**
     * Get providers from configuration / loaders (sharepoint)
     * @param  {[type]} configs providers configuration
     * @param  {[type]} force   enforce the dunamically instantiable configuration
     * @return {[type]}         a list of providers
     */
    async _getProviderInstances(configs, force) {
        let promises = configs.map(config => {
            return this._getProviderInstance(config, force);
        });
        let instances = await Promise.all(promises);

        // split loader and provider instances
        let loaders = [], loadedInstances = [];
        for (var instance of instances) {
            if (instance.loader) {
                loaders.push(instance);
            } else {
                loadedInstances.push(instance);
            }
        }

        // wait for loaders to give instances
        let loaderInstances = (await Promise.all(loaders.map(loader => {
            return loader.loadProviders(this._getProviderInstance.bind(this));
        }))).flat().filter(Boolean);

        return [...loadedInstances, ...loaderInstances];
    }

    _addOptions(config, options) {
        config.params = {
            ...config.params,
            ...options
        };
    }

    async registerFromConfig(configs, options, relativePath) {
        this.providers.length = 0;
        this.ready = false;
        configs.forEach(config => {
            this._addOptions(config, options);
        });

        /*
         * Backware compatibility support for loading providers from another public path (lib/ folder)
         * Note: if relativePath is defined, we should ensure that no other webpack dynamic imports are
         * done at the same time to avoid loading other js files from the from public path
         */
        let oldPublicPath = __webpack_public_path__;
        if (relativePath) {
            __webpack_public_path__ = __webpack_public_path__ + this._getRelativePath(relativePath);
        }

        try {
            this.providers = await this._getProviderInstances(configs, options, true);
        } catch (e) {
            console.log(e);
            console.log('There was a problem instantiating this provider');
        } finally {
            __webpack_public_path__ = oldPublicPath; // eslint-disable-line require-atomic-updates
            this.ready = true;
        }
    }

    async registerNewFromConfig(configs, options, relativePath) {
        this.newProviders.length = 0;
        this.newReady = false;
        configs.forEach(config => {
            this._addOptions(config, options);
        });

        // see comment in registerFromConfig()
        let oldPublicPath = __webpack_public_path__;
        if (relativePath) {
            __webpack_public_path__ = __webpack_public_path__ + this._getRelativePath(relativePath);
        }
        try {
            this.newProviders = await this._getProviders(configs, options, true);
        } catch (e) {
            console.log(e);
            console.log('There was a problem instantiating this provider');
        } finally {
            __webpack_public_path__ = oldPublicPath; // eslint-disable-line require-atomic-updates
            this.newReady = true;
        }
    }
}

export default new ProviderRegistry();
