import {jQueryFnWrapper} from './jqueryWrapper';
import i18n from './i18n.js';

var _ = i18n('repositoryNavigator');

/**
 * Side Panel Promote Place class
 */

class SidePanelPromotePlace {
    constructor(element, {repository, mimetype, places, errorCallback, successCallback}) {
        this.$element = $(element);
        this.mimetype = mimetype;
        this.repository = repository;
        this.places = places;
        this.errorCallback = errorCallback;
        this.successCallback = successCallback;
        this.init();
    }

    init() {
        var panel = $(
            '<div class="side-panel side-panel-promote-place">' +
            '    <div class="backdrop"></div>' +
            '    <div class="panel-content">' +
            '        <button type="button" class="close">' +
            '            <span aria-hidden="true">x</span>' +
            '        </button>' +
            '        <div class="panel-header">' +
            '            <div class="title">' + _("Promote place") + '</div>' +
            '            <div class="description">' + _("Copy the content of the source place to the target place removing any models in the target place not in the source place.") + '</div>' +
            '            <div class="source-place"></div>' +
            '        </div>' +
            '        <div class="panel-body">' +
            '            <div class="label label-to">' + _("To") + '</div>' +
            '            <div class="target-place">' +
            '                <div class="dropdown btn-group change-place-section">' +
            '                    <button type="button" class="changeplace dropdown-toggle" data-toggle="dropdown">' +
            '                        <span class="repository-icon place-container"></span>' +
            '                        <span class="current-place select-place">' + _("Select a place") + '</span>' +
            '                        <span class="caret"></span></button>'+
            '                    <ul class="dropdown-menu dropdown-menu-right"></ul>' +
            '                </div>' +
            '            </div>' +
            '            <div class="label label-version">' + _("Version (optional)") + '</div>' +
            '            <div class="version"><input id="version-input" name="version-input" class="form-control"/></div>' +
            '            <div class="label label-state">' + _("State (optional)") + '</div>' +
            '            <div class="state">' +
            '                <div class="dropdown btn-group state-section">' +
            '                    <button type="button" class="state-btn dropdown-toggle" data-toggle="dropdown">' +
            '                        <span class="current-state select-state">' + _("Select a state") + '</span>' +
            '                        <span class="caret"></span></button>'+
            '                    <ul class="dropdown-menu dropdown-menu-right">' +
            '                        <li class="item no-state"><a href="#" title="' + _("No state") + '">' + _("No state") + '</a></li>' +
            '                        <li class="item" data-state="Draft"><a href="#" title="' + _("Draft") + '">' + _("Draft") + '</a></li>' +
            '                        <li class="item" data-state="Pending Approval"><a href="#" title="' + _("Pending Approval") + '">' + _("Pending Approval") + '</a></li>' +
            '                        <li class="item" data-state="Published"><a href="#" title="' + _("Published") + '">' + _("Published") + '</a></li>' +
            '                    </ul>' +
            '                </div>' +
            '            </div>' +
            '        </div>' +
            '        <div class="footer">' +
            '            <button class="btn promote-btn" type="button" disabled>' + _("Promote") + '</button>' +
            '        </div>' +
            '    </div>' +
            '</div>'
        );

        var getRepositoryIcon = function(repository) {
            if (typeof repository.getImage === "function") {
                return 'img-' + repository.getImage();
            } else {
                return 'img-' + repository.type;
            }
        };

        var place = panel.find('.source-place');
        var iconClass = getRepositoryIcon(this.repository);
        $('<i class = "' + iconClass +'"></i><span>' + this.repository.getName() + '</span>').appendTo(place);

        let $placesDropdown = panel.find('.target-place .dropdown-menu');
        for (var i=0; i < this.places.length; i++) {
            var descriptor = this.places[i].descriptor;
            var repository = this.places[i].repository;
            var iconcls = getRepositoryIcon(repository);
            if(repository === this.repository) {
                continue;
            }
            let $li = $(`<li class="item folder place" data-type="${descriptor.repositoryType}"><a href="#" title="${descriptor.getName()}"><i class="${iconcls}"></i><span>${descriptor.getName()}</span></a></li>`);
            $li.data('descriptor', descriptor).data('repository', repository).appendTo($placesDropdown);
        }

        var closePanel = () => {
            this.$element.unbind('.sidePanel');
            $('.repository-navigator-commandbar').children().attr("disabled", false);
            panel.remove();
        };

        this.$element.one('click.sidePanel', '.close, .backdrop', () => {
            closePanel();
            this.errorCallback();
        });

        this.$element.on('click.sidePanel', '.change-place-section.dropdown li', ({currentTarget}) => {
            const $this = $(currentTarget);
            this.repository = $this.data('repository');
            this.descriptor = $this.data('descriptor');
            // update dropdown label
            var currentPlace = panel.find('.change-place-section .changeplace .current-place');
            currentPlace.removeClass('select-place');
            var repositoryIcon = panel.find('.change-place-section .changeplace .repository-icon');
            repositoryIcon.html('');
            let repositoryName = this.repository.getName();
            currentPlace.html(repositoryName);
            currentPlace.attr('title', repositoryName);
            var iconClass = getRepositoryIcon(this.repository);
            $('<i class = "' + iconClass + '"/>').appendTo(repositoryIcon);
            panel.find('.promote-btn').removeAttr('disabled');
        });

        this.$element.on('click.sidePanel', '.state-section.dropdown li', ({currentTarget}) => {
            const $this = $(currentTarget);
            this.state = $this.data('state');
            // update dropdown label
            var currentState = panel.find('.state-section .state-btn .current-state');
            currentState.removeClass('select-state');
            let stateLabel;
            if(!this.state) {
                currentState.addClass('select-state');
                stateLabel = _("Select a state");
            } else {
                stateLabel = $this.text();
            }
            currentState.html(stateLabel);
            currentState.attr('title', stateLabel);
        });

        this.$element.one('click.sidePanel', '.side-panel .footer button.promote-btn', ({currentTarget}) => {
            const version = panel.find('#version-input').val();
            closePanel();
            this.successCallback(this.repository, this.descriptor, version, this.state);
        });

        this.$element.find('.panel-content').animate({ width: 'slide' }, { duration: 250 });
        panel.appendTo(this.$element);
    }
}

jQueryFnWrapper('sidePanelPromotePlace', SidePanelPromotePlace);
