import {jQueryFnWrapper} from './jqueryWrapper';
import i18n from './i18n.js';

var _ = i18n('repositoryNavigator');

/**
 * Side Panel class
 */

class SidePanel {
    constructor(element, {descriptor, repository, selection, count, places, command, errorCallback, successCallback}) {
        this.$element = $(element);
        this.descriptor = descriptor;
        this.repository = repository;
        this.selection = selection;
        this.count = count;
        this.places = places;
        this.command = command;
        this.errorCallback = errorCallback;
        this.successCallback = successCallback;
        this.init();
    }

    init() {
        var that = this;

        var panel = $(
            '<div class="side-panel">' +
            '    <div class="backdrop"></div>' +
            '    <div class="panel-content">' +
            '        <button type="button" class="close">' +
            '            <span aria-hidden="true">x</span>' +
            '        </button>' +
            '        <div class="panel-header">' +
            '            <div class="title move-title">' + _("Move %d item", { count: this.count, sprintf: [this.count] }) + '</div>' +
            '            <div class="title copy-title">' + _("Copy %d item", { count: this.count, sprintf: [this.count] }) + '</div>' +
            '            <div class="label-from">' + _("From") + '</div>' +
            '            <div class="origin-place place-container"></div>' +
            '            <div class="origin-path"></div>' +
            '            <div class="selected-element"></div>' +
            '            <hr>'+
            '            <div class="label-to">' + _("To") + '</div>' +
            '            <div class="destination-place">' +
            '                <div class="dropdown btn-group change-place-section">' +
            '                    <button type="button" class="changeplace dropdown-toggle" data-toggle="dropdown">' +
            '                        <span class="repository-icon place-container"></span>' +
            '                        <span class="current-place select-place">' + _("Select a place") + '</span>' +
            '                        <span class="caret"></span></button>'+
            '                    <ul class="dropdown-menu dropdown-menu-right"</ul>' +
            '                </div>' +
            '            </div>' +
            '            <div class="locationBar"></div>' +
            '        </div>' +
            '        <div class="panel-body">' +
            '            <div class="destination-header">' + _("Choose a destination") +
            '            </div>' +
            '            <div class="repository-content"></div>' +
            '        </div>' +
            '        <div class="footer">' +
            '            <button type="button" class="move-button">' + _("Move here") + '</button>' +
            '            <button type="button" class="copy-button">' + _("Copy here") + '</button>' +
            '        </div>' +
            '    </div>' +
            '</div>'
        );

        var getRepositoryIcon = function(repository) {
            if (typeof repository.getImage === "function") {
                return 'img-' + repository.getImage();
            } else {
                return 'img-' + repository.type;
            }
        };

        let $placesDropdown = panel.find('.destination-place .dropdown-menu');
        for (var i=0; i < that.places.length; i++) {
            var descriptor = that.places[i].descriptor;
            var repository = that.places[i].repository;
            if(that.command === 'copy' && repository.getName() ===  that.selection[0].repository.name) {
                continue;
            }
            var iconcls = getRepositoryIcon(repository);

            let $li = $(`<li class="item folder place" data-type="${descriptor.repositoryType}"><a href="#" title="${descriptor.getName()}"><i class="${iconcls}"></i><span>${descriptor.getName()}</span></a></li>`);
            $li.data('descriptor', descriptor).data('repository', repository).appendTo($placesDropdown);
        }
        panel.find('.footer button').hide();

        var list = panel.find('.repository-content');
        list.data('repository', this.repository);

        if (this.selection.length === 1 && this.count === 1) {
            var icon;
            if (this.selection[0].isFolder()) {
                icon = 'img-folder';
            } else {
                icon = 'img-file';
            }
            $('<div class="item" ' + (this.selection[0].isFolder() ? "" : 'data-mimetype="' + this.selection[0].getMimeType() + '"') 
                + '><i class="' + icon + '"></i><span title="' + this.selection[0].getName() + '">' + this.selection[0].getName() + '</span></div>').appendTo(panel.find('.selected-element'));
        } else {
            $('<div class="item"></div>').appendTo(panel.find('.selected-element'));
        }

        if(this.command === 'copy') {
            panel.find('.panel-header .move-title').remove();
            panel.find('.footer .move-button').remove();
        }

        if (this.command === 'move') {
            panel.find('.panel-header .copy-title').remove();
            panel.find('.footer .copy-button').remove();
        }

        // Set origin place
        var originPlace = panel.find('.panel-header .origin-place');
        iconcls = getRepositoryIcon(that.repository);
        $('<i class = "' + iconcls +'"></i><span>' + that.repository.getName() + '</span>').appendTo(originPlace);


        // Set origin path
        var originPath = panel.find('.panel-header .origin-path');
        var originPaths = (this.selection[0].getPath() && this.selection[0].getPath().split('/').filter(function(a) {
            return a !== "";
        })) || [];
        if(this.selection[0].isFolder() && originPaths.length > 0) {
            originPaths.pop();
        }
        originPaths.forEach(function(path, index) {
            if (path !== "") {
                var lastPath = index === originPaths.length - 1;
                $((index === 0 ? '<span class="path-element path-separator"></span>' : '') + '<span>' + path + '</span>' + (!lastPath ? '<span class="path-element path-separator"></span>' : ''))
                    .appendTo(originPath);
            }
        });

        var containFolders = function(descriptors) {
            var hasFolders = false;
            descriptors.forEach(function(descriptor) {
                if (descriptor.isFolder()) {
                    hasFolders = true;
                    return false;
                }
            });
            return hasFolders;
        };

        var listFolderContent = function(repository, descriptor) {
            that.currentDescriptor = descriptor;
            repository.getFolderContent({
                descriptor: descriptor,
                mimetype: descriptor.getMimeType(),
                success: function(descriptors) {
                    descriptors.sort(function(a, b) { // sort asc
                        if (a.isFolder() !== b.isFolder()) {
                            return a.isFolder() ? -1 : 1;
                        }
                        return a.getName().toLowerCase().localeCompare(b.getName().toLowerCase());
                    });
                    filterFolderContent(that.selection, descriptors).forEach(function(descriptor) {
                        var mimetype = (descriptor.getMimeType() ? descriptor.getMimeType() : "folder");
                        if (descriptor.isFolder()) {
                            $('<div class="item folder content" data-mimetype="' + mimetype + '"><i class="img-folder"></i><span title="' + descriptor.getName() + '">' + descriptor.getName() + '</span></div>').data('descriptor', descriptor).appendTo(list);
                        }
                    });
                    $('<div class="content folder" id="add-new-folder"><i class="new-folder"></i><span>' + _("New folder") + '</span></div>').appendTo(list);
                    $('<div class="content folder" id="add-new-foldername"><i class="new-folder"></i><input type="text" placeholder="' + _("Enter your folder name") + '"/></div>').appendTo(list);
                    if (!containFolders(descriptors)) {
                        $('<div class="empty">' + _("This folder doesn't have any subfolders.") + '</div>').appendTo(list);
                    }
                    $('.footer button').show();
                },
                error: function(message) {
                    panel.remove();
                    that.$element.userMessage({
                        'message': message
                    });
                    that.errorCallback();
                }
            });
        };

        /**
         * Function to remove selected folder descriptors from the content of a folder
         * @param  [descriptor] selection of descriptors to move
         * @param  [descriptor] content of the listed folder
         */
        var filterFolderContent = function(selection, content) {
            var filtered = [];
            content.forEach(function(descriptor1) {
                var ok = true;
                selection.forEach(function(descriptor2) {
                    if (descriptor1.attributes.id === descriptor2.attributes.id) {
                        ok = false;
                        return;
                    }
                });
                if (ok) {
                    filtered.push(descriptor1);
                }
            });

            return filtered;
        };

        var updateStatusBar = function(repository, descriptor) {
            var status = panel.find('.panel-header .locationBar');
            status.empty();
            status.data('repository', repository);

            var paths = (descriptor.getPath && descriptor.getPath().split('/').filter(function(a) {
                return a !== "";
            })) || [];

            if (paths.length >= 2) {
                $('<span class="path-element path-back"></span>').appendTo(status);
            }
            paths.forEach(function(path, index) {
                if (path !== "") {
                    var lastPath = index === paths.length - 1;
                    var classes = "";
                    if (!lastPath && paths.length >= 3) {
                        classes = ' class="path short-path"';
                    } else if (!lastPath) {
                        classes = ' class="path"';
                    } else if (lastPath && paths.length >= 3) {
                        classes = ' class="short-path"';
                    }
                    //set back button data
                    if (index === paths.length - 2 && paths.length >= 2) {
                        status.find('.path-back').data('path', '/' + paths.slice(0, index + 1).join('/'));
                    }
                    if (!(paths.length > 3 && index < paths.length - 3)) {
                        $('<span' + classes + ' title="' + path + '">' + path + '</span>' +
                                (!lastPath ? '<span class="path-element path-separator"></span>' : ''))
                            .appendTo(status)
                            .data('path', '/' + paths.slice(0, index + 1).join('/'));
                    }
                }
            });
        };

        var changePlaceButton = function(repository) {
            var currentPlace = panel.find('.change-place-section .changeplace .current-place');
            currentPlace.removeClass('select-place');
            var repositoryIcon = panel.find('.change-place-section .changeplace .repository-icon');
            repositoryIcon.html('');
            let repositoryName = repository.getName();
            currentPlace.html(repositoryName);
            currentPlace.attr('title', repositoryName);
            var iconClass = getRepositoryIcon(repository);
            $('<i class = "' + iconClass + '"/>').appendTo(repositoryIcon);
        };

        var closePanel = function() {
            that.$element.unbind('.sidePanel');
            $('.repository-navigator-commandbar').children().attr("disabled", false);
            panel.remove();
        };

        if(this.command === 'move') {
            changePlaceButton(this.repository);
            listFolderContent(this.repository, this.descriptor);
            updateStatusBar(this.repository, this.descriptor);
        }

        that.$element.on('click.sidePanel', '.change-place-section.dropdown li', function() {
            let $this = $(this);
            that.repository = $this.data('repository');
            var descriptor = $this.data('descriptor');
            list.html('');
            updateStatusBar(that.repository, descriptor);
            listFolderContent(that.repository, descriptor);
            changePlaceButton(that.repository);
        });

        that.$element.on('click.sidePanel', '#add-new-folder', function(e) {
            $(this).hide();
            $('#add-new-foldername').show();
            $('#add-new-foldername input').focus();
        });

        that.$element.on('keypress.sidePanel', '#add-new-foldername input', function(e) {
            if (e.which === 13 && $(this).val() !== "") {
                var newName = $(this).val();

                that.repository.newFolder({
                    descriptor: that.currentDescriptor,
                    newFolderName: newName,
                    success: function(newDescriptor) {
                        list.html('');
                        listFolderContent(that.repository, newDescriptor);
                        updateStatusBar(that.repository, newDescriptor);
                    },
                    error: function(message) {
                        that.$element.userMessage({
                            'title': _('Create Folder Error'),
                            'message': message
                        });
                        $('#add-new-foldername').hide();
                        $('#add-new-folder').show();
                        $(this).val('');
                    }
                });
            }
        });

        that.$element.on('click.sidePanel', '.panel-header .locationBar .path, .panel-header .locationBar .path-back', function(e) {
            var $element = $(this);
            var path = $element.data('path');
            var locationBar = $element.closest('.locationBar');

            var paths = path.split('/').filter(function(a) {
                return a !== "";
            });
            var repository = locationBar.data('repository');

            repository.getFolderDescriptorFromPath({
                path: '/' + paths.join('/'),
                success: function(descriptor) {
                    list.html('');
                    updateStatusBar(repository, descriptor);
                    listFolderContent(repository, descriptor);
                },
                error: function(message) {
                    that.$element.repositoryNavigator.userMessage({
                        message: message
                    });
                }
            });
        });

        that.$element.on('click.sidePanel', '.repository-content .item', function() {
            var descriptor = $(this).data('descriptor');
            list.html('');
            updateStatusBar(that.repository, descriptor);
            listFolderContent(that.repository, descriptor);
        });

        that.$element.one('click.sidePanel', '.close, .backdrop', function() {
            closePanel();
            that.errorCallback();
        });

        that.$element.one('click.sidePanel', '.side-panel .footer button', function() {
            // If the user has entered a new folder name and click on "move here" button, selection will
            // moved in this new folder.
            if ($('#add-new-foldername input').val() !== '') {
                that.repository.newFolder({
                    descriptor: that.currentDescriptor,
                    newFolderName: $('#add-new-foldername input').val(),
                    success: function(newDescriptor) {
                        closePanel();
                        that.successCallback(newDescriptor, that.selection, that.repository);
                    },
                    error: function(message) {
                        that.$element.userMessage({
                            'title': _('Create Folder Error'),
                            'message': message
                        });
                        that.errorCallback();
                    }
                });
            } else {
                closePanel();
                that.successCallback(that.currentDescriptor, that.selection, that.repository);
            }
        });

        that.$element.find('.panel-content').animate({ width: 'slide' }, { duration: 250 });
        panel.appendTo(this.$element);
    }
}

jQueryFnWrapper('sidePanel', SidePanel);
