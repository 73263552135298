import {jQueryFnWrapper} from './jqueryWrapper';
import {dateToString} from './utils.js';
import i18n from './i18n.js';

var _ = i18n('repositoryNavigator');

/**
 * Side Panel Restore Files class
 */

class SidePanelRestoreFiles {
    constructor(element, {repository, mimetype, errorCallback, successCallback}) {
        this.$element = $(element);
        this.mimetype = mimetype;
        this.repository = repository;
        this.errorCallback = errorCallback;
        this.successCallback = successCallback;
        this.init();
    }

    init() {
        var that = this;

        var panel = $(
            '<div class="side-panel side-panel-restore-files">' +
            '    <div class="backdrop"></div>' +
            '    <div class="panel-content">' +
            '        <button type="button" class="close">' +
            '            <span aria-hidden="true">x</span>' +
            '        </button>' +
            '        <div class="panel-header">' +
            '            <div class="title">' + _("Restore items") + '</div>' +
            '            <div class="place-restore-item"></div>' +
            '            <div class="content-header">' + _("Choose files to restore") + '</div>' +
            '        </div>' +
            '        <div class="panel-body">' +
            '            <div class="items-content">' +
            '                <ul class="items-container"></ul>' +
            '            </div>' +
            '        </div>' +
            '        <div class="footer">' +
            '            <button class="btn" type="button">' + _("Restore") + '</button>' +
            '        </div>' +
            '    </div>' +
            '</div>'
        );

        var getRepositoryIcon = function(repository) {
            if (typeof repository.getImage === "function") {
                return 'img-' + repository.getImage();
            } else {
                return 'img-' + repository.type;
            }
        };

        var place = panel.find('.place-restore-item');
        var iconClass = getRepositoryIcon(that.repository);
        $('<i class = "' + iconClass +'"></i><span>' + that.repository.getName() + '</span>').appendTo(place);

        var listItems = function(repository) {
            var ul = panel.find('ul.items-container').empty();
            repository.getRecoverableFiles({
                repository: repository,
                mimetype: that.mimetype,
                success: function(descriptors) {
                    let $restoreBtn = that.$element.find('.panel-content .footer button.btn');
                    if(!descriptors || descriptors.length === 0) {
                        $('<li class="empty">' + _('No items to restore.') + '</li>').appendTo(ul);
                        $restoreBtn.hide();
                    } else {
                        descriptors.sort(function(a, b){
                            return b.getUpdated() - a.getUpdated();
                        });
                        var html = '<li class = "header">' +
                            '    <div class="details">' +
                            '        <span class="multiple-select no-resizable" title="' + _('Select all') + '" data-toggle="tooltip"><i></i></span>'+
                            '        <span class="name" title="' + _('Name') + '" data-toggle="tooltip">' + _('Name') + '</span>' +
                            '        <span class="path" title="' + _('Original Location') + '" data-toggle="tooltip">' + _('Original Location') + '</span>' +
                            '        <span class="deleted-date" title="' + _('Deleted Date') + '" data-toggle="tooltip">' + _('Deleted Date') + '</span>' +
                            '    </div>' +
                            '</li>';
                        $(html.replace(/>\s+</g, '><')).appendTo(ul);
                        
                        $restoreBtn.addClass('disabled');
                        descriptors.forEach(function(descriptor){
                            var deletedDate = getDeletedDate(descriptor);
                            var mimetypeDescriptor = descriptor.getMimeType();
                            var li = $('<li class="item" data-mimetype="' + mimetypeDescriptor +'"></li>').appendTo(ul);
                            html = '<div class="details">' +
                                '    <span class="item-multiple-select no-resizable"><i class="multiple-select"></i></span>'+
                                '    <span class="item-name" data-toggle="tooltip">' +
                                '        <i class="img-file"></i>' +
                                '        <span class="name" title="' + descriptor.getName() + '" data-toggle="tooltip">' + descriptor.getName() + '</span>' +
                                '    </span>' +
                                '    <span class="item-path" title="' + showPathName(descriptor.getPath()) + '" data-toggle="tooltip">' + showPathName(descriptor.getPath()) + '</span>' +
                                '    <span class="item-deleted-date" title="' + deletedDate + '" data-toggle="tooltip">' + deletedDate + '</span>' +
                                '</div>';
                            li.data('descriptor', descriptor);
                            $(html.replace(/>\s+</g, '><')).appendTo(li);
                        });
                    }
                },
                error: function(message) {
                    panel.remove();
                    that.$element.userMessage({
                        'message': message
                    });
                }
            });
        };

        var showPathName = function(path) {
            return path.length > 1 ? path.substring(1) : '-';
        };

        var getDeletedDate = function(descriptor) {
            var updated = descriptor.getUpdated && descriptor.getUpdated();
            if (typeof updated === "object") {
                if (isNaN(updated.getTime())) {
                    updated = null;
                } else {
                    updated = dateToString(updated);
                }
            }
            return updated || '-';
        };

        var closePanel = function() {
            that.$element.unbind('.sidePanel');
            $('.repository-navigator-commandbar').children().attr("disabled", false);
            panel.remove();
        };

        listItems(this.repository);


        /*
         * Click on select all.
         */
        that.$element.on('click.sidePanel', '.items-content ul > li.header span.multiple-select i', function() {
            let $selectAllBtn = $(this); 
            let $items = that.$element.find('.items-content ul li.item');
            let $restoreBtn = that.$element.find('.panel-content .footer button.btn');
            if ($selectAllBtn.hasClass('clicked')) {
                $selectAllBtn.removeClass('clicked');
                $items.removeClass('active');
                $restoreBtn.addClass('disabled');
                that.multipleSelection = false;
            } else {
                $selectAllBtn.addClass('clicked');
                $items.addClass('active');
                $restoreBtn.removeClass('disabled');
                that.multipleSelection = true;
            }
        });


        /*
         * Click on item button.
         */
        that.$element.on('click.sidePanel', '.items-content ul > li.item', function() {
            $('side-panel').children('div.tooltip').remove();
            var $item = $(this);
            let $restoreBtn = that.$element.find('.panel-content .footer button.btn');
            if($item.hasClass('active')){
                $item.removeClass('active');
                that.multipleSelection = false;
                if (!that.$element.find('.items-content ul > li.item.active').length) {
                    $restoreBtn.addClass('disabled');
                }
            } else {
                $item.addClass('active');
                $restoreBtn.removeClass('disabled');
                that.multipleSelection = true;
            }
        });

        that.$element.one('click.sidePanel', '.close, .backdrop', function() {
            closePanel();
            that.errorCallback();
        });

        that.$element.one('click.sidePanel', '.side-panel .footer button', function() {
            if ($(this).hasClass('disabled')) {
                return;
            }
            var items = $('.items-content ul > li.item.active');
            var descriptors = [];
            for(var i = 0; i < items.length; i++) {
                descriptors.push($(items[i]).data('descriptor'));
            }
            closePanel();
            that.successCallback(descriptors);
        });

        that.$element.find('.panel-content').animate({ width: 'slide' }, { duration: 250 });
        panel.appendTo(this.$element);
    }
}

jQueryFnWrapper('sidePanelRestoreFiles', SidePanelRestoreFiles);
