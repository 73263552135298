import i18next from 'i18next';

/*
 * _("string") => translate the string
 * _("string %d", 4) => translate the string and replace %d with 4 (see sprintf)
 * _("%d string", {count: 4, sprintf: [4]}) => translate the string, replace %d and use the plurial form if > 0
 */
export default function(ns) {
    return function(...args){
        if (args.length > 1 && typeof args[1] === "object") {
            return i18next.t(args[0], $.extend({},{
                ns: ns,
                postProcess: 'sprintf'
            }, args[1]));
        } else {
            return i18next.t(args[0], {
                ns: ns,
                postProcess: 'sprintf',
                sprintf: args.slice(1)
            });
        }
    };
}
