import {jQueryFnWrapper} from './jqueryWrapper';
import i18n from './i18n.js';

var _ = i18n('repositoryNavigator');


/**
 * Side Panel class
 */
class SidePanelIssueEditor {
    constructor(element, repositoryId, serverUrl, tinymceSkinUrl = null) {
        this.$element = $(element);
        this.repositoryId = repositoryId;
        this.serverUrl = serverUrl.replace('/publicapi', '');
        this.tinymceSkinUrl = tinymceSkinUrl;
        this.init();
    }

    init() {
        var that = this;

        var panel = $(
            '<div class="side-panel side-panel-issue-editor">' +
            '    <div class="backdrop"></div>' +
            '    <div class="panel-content">' +
            '        <button type="button" class="exportCSV" title="' + _("Export all issues to a CSV file") + '"" data-toggle="tooltip">' +
            '            <i class="fa fa-download"></i>' +
            '        </button>' +
            '        <button type="button" class="showDescription">' +
            '            <i class="fa fa-bars"></i>' +
            '        </button>' +
            '        <button type="button" class="close">' +
            '            <span aria-hidden="true">x</span>' +
            '        </button>' +
            '        <div class="panel-header">' +
            '            <div class="title">' + _("Issues") + '</div>' +
            '        </div>' +
            '        <div class="panel-body">' +
            '            <div id="issueEditorPlugin"></div>' +
            '        </div>' +
            '    </div>' +
            '</div>'
        );
        panel.appendTo(this.$element);

        that.$element.one('click.sidePanel', '.close, .backdrop', function() {
            that.$element.unbind('.sidePanel');
            $('.repository-navigator-commandbar').children().attr("disabled", false);
            panel.remove();
        });

        this.$element.off('click', 'div.panel-content button.showDescription');
        this.$element.on('click', 'div.panel-content button.showDescription', function() {
            $(this).toggleClass("toggled");
            that.issueEditor.toggleSeeDescription();
        });

        this.$element.off('click', 'div.panel-content button.exportCSV');
        this.$element.on('click', 'div.panel-content button.exportCSV', function() {
            that.issueEditor.exportCSV();
        });

        import('@trisotech/issue-editor').then(async ({IssueController}) => {
            that.issueEditor = new IssueController({
                repositoryId: that.repositoryId,
                serverUrl: that.serverUrl,
                elementAPI: null,
                sidePanelDiv: panel.find('#issueEditorPlugin')[0],
                tinymceSkinUrl: this.tinymceSkinUrl
            });

            await that.issueEditor.init();

            that.$element.find('.panel-content').animate({ width: 'slide' }, { duration: 250 });
            panel.appendTo(that.$element);
        }).catch(e => {
            throw e;
        });
    }
}

jQueryFnWrapper('sidePanelIssueEditor', SidePanelIssueEditor);
