import i18n from './i18n.js';
import providerRegistry from './providerRegistry';
import {dateToString, layoutColumns} from './utils.js';
import {jQueryWrapper} from './jqueryWrapper';
import {saveAs} from 'file-saver';

import './message.js';
import './share.js';
import './sidePanel.js';
import './versions.js';
import "./sidePanelIssueEditor.js";
import "./sidePanelRestoreFiles.js";
import "./sidePanelPromotePlace.js";

export {providerRegistry};
export {UserMessage} from './message.js';

import { debounce } from 'lodash';

// Backware compatibility, to remove once all projects are migrated to webpack
window.providerRegistry = providerRegistry;

var _ = i18n('repositoryNavigator');


var hasUploadDownloadCapacity = !!Blob && !!FileReader && ("files" in DataTransfer.prototype || /chrome/.test(navigator.userAgent.toLowerCase()));

/*
 *
 * Repository Navigator Plugin
 *
 */
export class RepositoryNavigator {
    constructor(element, {
        mode = "open",
        callbackOk = function(provider, descriptor) {},
        callbackCancel = function() {},
        contextualMenuCallback = function(provider, descriptor) {},
        nameSuggestion = '',
        allowUploadDownload = false,
        mimetype,
        mimetypes,
        providerFilterFn,
        readOnly = false,
        buttonName,
        selection,
        allowedUploadFile,
        showIssueEditor = false,
        tinymceSkinUrl = null
    } = {}) {
        this.repositoryNavigator = $(element);
        this.readOnly = readOnly;
        // Do not allow the save modal in a readOnly navigator
        this.mode = this.readOnly ? 'open' : mode;
        this.callbackOk = function() {
            if (callbackOk) {
                callbackOk.apply(this, arguments);
            }
        };
        this.callbackCancel = function() {
            if (callbackCancel) {
                callbackCancel.apply(this, arguments);
            }
        };
        this.contextualMenuCallback = function() {
            if (contextualMenuCallback) {
                return contextualMenuCallback.apply(this, arguments);
            }
            return [];
        };
        this.buttonName = buttonName;
        this.selection = selection;
        this.nameSuggestion = nameSuggestion;
        this.allowUploadDownload = allowUploadDownload && hasUploadDownloadCapacity;
        var defaultAllowedUploadFileOptions = {
            mimeType: "application/json",
            extension: ".json",
            accept: null, // By default we accept all files for upload
            blob: "application/json"
        };
        this.allowedUploadFile = allowedUploadFile ? allowedUploadFile : defaultAllowedUploadFileOptions;
        this.mimetype = mimetype;
        this.mimetypeWithTemplate = [];
        if(mimetypes) {
            for (const [key, value] of Object.entries(mimetypes)) {
                if(!mimetype || mimetype.includes(key)) {
                    if(value.templateAvailable){
                        this.mimetypeWithTemplate.push(value);
                    }
                }
            }
        }
        this.mimetypeWithTemplate = this.mimetypeWithTemplate.sort((a, b) => a.label ? a.label.localeCompare(b.label) : -1);
        this.providerFilterFn = providerFilterFn;
        this.multipleSelection = false;
        this.showIssueEditor = showIssueEditor;
        this.tinymceSkinUrl = tinymceSkinUrl;
        this.currentSearchTerm = '';
        this.globalSearch = false;
        this.init();
        this.debounceUpdateSearch = debounce(this.updateSearch.bind(this), 300);
    }

    init(){
        var that = this;

        $(('<div class="repository-navigator' + (this.readOnly ? ' readOnly' : '') + '" style="position:relative; width:100%;height:100%;">' +
                (this.readOnly ? '' :
                    '  <div class="repository-navigator-commandbar">' +
                    '    <button command="create"><span class="icon"></span><span>' + _('New') + '</span></button>' +
                    '    <button command="browseFolder"><span class="icon"></span><span>' + _('Browse to Folder') + '</span></button>' +
                    '    <button command="rename"><span class="icon"></span><span>' + _('Rename') + '</span></button>' +
                    '    <button command="delete"><span class="icon"></span><span>' + _('Delete') + '</span></button>' +
                    '    <button command="copyTo"><span class="icon"><i class="fa fa-copy fa-lg"></i></span><span>' + _('Copy to') + '</span></button>' +
                    '    <button command="moveTo"><span class="icon"></span><span>' + _('Move to') + '</span></button>' +
                    '    <button command="restore"><span class="icon"><i class="fa fa-undo fa-lg"></i></span><span>' + _('Restore') + '</span></button>' +
                    '    <button command="promote"><span class="icon"><i class="fa fa-level-up fa-lg"></i></span><span>' + _('Promote place') + '</span></button>' +
                    (this.allowUploadDownload ? '<button command="upload"><span class="icon"></span><span>' + _('Upload') + '</span></button>' : '') +
                    (this.allowUploadDownload ? '<button command="download"><span class="icon"></span><span>' + _('Download') + '</span></button>' : '') +
                    (this.mode === 'open' ? '<button command="versions"><span class="icon"></span>' + _('Versions') + '</span></button>' : '') +
                    '  </div>') +
                '  <div class="repository-navigator-center">' +
                '    <div class="navigator-repositories" tabindex="1">' +
                '      <div class="title">' + _('Places') + ' </div>' +
                '    </div>' +
                '    <div class="repository-navigator-tree" tabindex="1" style="display:none;"></div>' +
                '    <div class="selectMessage">' + _('Please select a place.') + '</div>' +
                '  </div>' +
                '</div>').replace(/>\s+</g, '><')) // we need to remove space between tags so we don't have undesired space between inline-block blocks
            .appendTo(this.repositoryNavigator);

        let createBtn = $(this.repositoryNavigator).find('button[command=create]');
        this.getNewFileOptions(createBtn);
        createBtn.on("mouseenter", function() {createBtn.addClass("hover");});
        createBtn.on("mouseleave", function() {createBtn.removeClass("hover");});

        if (this.mode === 'open') {
            let $search = $(
                '<div class="search">' +
                '    <i class="fa fa-search" aria-hidden></i>' +
                `    <input class="search-input form-control" placeholder="${_("Search Place")}" autocomplete="off" spellcheck="false" autocorrect="off"/>` +
                '    <i class="search-clear fa fa-times-circle"></i>' +
                '</div>'
            );
            if (!this.readOnly) {
                this.repositoryNavigator.find('.repository-navigator-commandbar').append($search);
            } else {
                this.repositoryNavigator.find('.repository-navigator-center').append($search);
            }
        }

        /*
         * bind delete / F2 keypress / Ctrl+x / Ctrl+a / Ctrl+shift+n / Ctrl+d
         */
        if (!this.readOnly) {
            this.repositoryNavigator.keydown(function(e) {
                if (e.which === 46 && !$(e.target).is('input')) { //delete button pressed not in a text input context
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=delete]').click();
                }
                if (e.which === 113 && !$(e.target).is('input')) { //F2 button
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=rename]').click();
                }
                if (String.fromCharCode(e.which).toLowerCase() === 'a' && (e.ctrlKey || e.metaKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('.repository-navigator-tree ul > li.header span.multiple-select i').click();
                }
                if (String.fromCharCode(e.which).toLowerCase() === 'c' && (e.ctrlKey || e.metaKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=copyTo]').click();
                }
                if (String.fromCharCode(e.which).toLowerCase() === 'x' && (e.ctrlKey || e.metaKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=moveTo]').click();
                }
                // Ctrl+shift+n do not works in Chrome
                if (String.fromCharCode(e.which).toLowerCase() === 'n' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=create]').click();
                }
                if (String.fromCharCode(e.which).toLowerCase() === 'd' && (e.ctrlKey || e.metaKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).find('button[command=delete]').click();
                }
            });
        }

        /*
         * Drag and Drop
         */
        if (this.allowUploadDownload && !this.readOnly) {
            that.repositoryNavigator.on('dragenter', '.navigator-column', function(e) {
                var $this = $(this);
                e.stopPropagation();
                e.preventDefault();
                $this.addClass('dragInProgress');
            });

            that.repositoryNavigator.on('dragleave', '.navigator-column', function(e) {
                var $this = $(this);
                $this.removeClass('dragInProgress');
                return false;
            });

            that.repositoryNavigator.on('dragover', '.navigator-column', function(e) {
                e.stopPropagation();
                e.preventDefault();
                e.originalEvent.dataTransfer.dropEffet = 'copy';
                return false;
            });

            that.repositoryNavigator.on('drop', '.navigator-column', function(e) {
                e.stopPropagation();
                e.preventDefault();

                $(this).removeClass('dragInProgress');

                var files = e.originalEvent.dataTransfer.files;
                var file = files[0];
                if (file && file.size <= 5242880*10) { // 50 MB
                    var btn = that.repositoryNavigator.find('button[command="upload"]').addClass('loading');

                    var reader = new FileReader();
                    reader.onload = function(e) {
                        var text = reader.result;
                        var currentRepository = that.getCurrentRepository();
                        var descriptor = that.getDescriptors()[0];
                        var nameWithoutExt = file.name.split('.')[file.name.split('.').length - 2] || file.name;

                        if (!descriptor || !descriptor.getActions().create) {
                            that.repositoryNavigator.userMessage({
                                title: _('Error uploading file'),
                                message: _('You can\'t upload a file in this folder')
                            });
                            btn.removeClass('loading');
                            return;
                        }

                        currentRepository.save({
                            descriptor: descriptor,
                            filename: nameWithoutExt,
                            mimetype: that.allowedUploadFile.mimeType,
                            data: text,
                            success: function(descriptor) {
                                // success
                                var ul = that.repositoryNavigator.find('.navigator-column > ul');
                                that.addLi(currentRepository, descriptor, ul);
                                layoutColumns(ul);
                                btn.removeClass('loading');
                            },
                            error: function(data) {
                                // fail
                                btn.removeClass('loading');
                                that.repositoryNavigator.userMessage({
                                    title: _('Error uploading file')
                                });
                            }
                        });
                    };

                    reader.onerror = function(e) {
                        btn.removeClass('loading');
                        that.repositoryNavigator.userMessage({
                            title: _('Error reading file')
                        });
                    };

                    reader.readAsText(file, 'utf-8');
                } else {
                    that.repositoryNavigator.userMessage({
                        message: _('File size is more than 5MB.')
                    });
                }
            });
        }

        /*
         * Click on a path in the location bar
         */
        this.repositoryNavigator.on('click', '.repository-navigator-status > .locationBar > .path', function(e) {
            var $this = $(this);
            var path = $this.data('path');
            var locationBar = $this.closest('.locationBar');

            $this.addClass('loading');

            var paths = path.split('/').filter(function(a) {
                return a !== "";
            });

            var repository = locationBar.data('repository');
            // remove the repository name
            for (var i = 0; i < 1; i++) {
                paths.shift();
            }

            // generate a descriptor
            repository.getFolderDescriptorFromPath({
                path: '/' + paths.join('/'),
                success: function(descriptor) {
                    that.loadFolder(repository, descriptor);
                },
                error: function(message) {
                    $this.removeClass('loading');
                    this.repositoryNavigator.userMessage({
                        message: message
                    });
                }
            });
        });

        /*
         * remove selection by clicking in the ul
         */
        this.repositoryNavigator.on('click', '.repository-navigator-tree ul', function(e) {
            if (e.target.tagName === "LI") {
                return;
            }
            var $this = $(this);
            that.removeOpenSaveBtn($this.children('li.active').removeClass('active'));
            that.lastClickeditem = null;
            that.repositoryNavigator.find('.repository-navigator-tree ul > li.header span.multiple-select i').removeClass('clicked');
            var descriptors = that.getDescriptors();
            // update command/action bar
            if (descriptors && descriptors.length === 1 && descriptors[0].isFolder()) {
                that.refreshCommandBar(descriptors, {
                    rename: false,
                    'delete': false,
                    versions: false,
                    copyTo: false,
                    moveTo: false,
                    download: false
                });
            } else {
                that.refreshCommandBar(descriptors, {
                    download: false
                });
            }
            return false;
        });

        /*
         * remove selection by clicking in the repositories UL
         */
        this.repositoryNavigator.on('click', '.navigator-repositories ul.repositories-column', function(e) {
            if ($(e.target).closest('ul.repositories-column li').length > 0) {
                return;
            }
            var $this = $(this);
            $this.children('li.active').removeClass('active');
            that.refreshCommandBar();
            that.removeNavigator();
            return false;
        });

        /*
         * Click on a Folder / file
         */
        this.repositoryNavigator.on('click', '.repository-navigator-tree ul > li.item', function(e) {
            /* eslint complexity:off */
            $('body').children('div.tooltip').remove();
            var $this = $(this);
            if (e.target.tagName === 'INPUT' && $this.hasClass('active')) { // Do not remove selection when clicking on a input
                return false;
            }

            $('.repository-navigator-tree ul > li.header span.multiple-select i').removeClass('clicked');
            var pos = $this.attr('data-pos');
            var descriptors, filter;

            if (!$this.hasClass('active')) {
                if (!that.lastClickeditem) {
                    that.lastClickeditem = pos;
                }
                if (e.ctrlKey || e.metaKey || e.shiftKey) {
                    that.removeOpenSaveBtn($this.siblings('.active'));
                    that.multipleSelection = true;
                } else {
                    that.removeOpenSaveBtn($this.siblings('.active').removeClass('active'));
                    that.multipleSelection = false;
                }
                if (e.shiftKey && that.lastClickeditem !== pos) {
                    if (that.lastClickeditem < pos) {
                        that.setSelectedItems(that.lastClickeditem, pos);
                    } else {
                        that.setSelectedItems(pos, that.lastClickeditem);
                    }
                } else {
                    $this.addClass('active');
                }
                descriptors = that.getDescriptors();
                filter = {
                    create: true,
                    upload: false
                };
                that.lastClickeditem = pos;
            } else {
                that.removeOpenSaveBtn($this.removeClass('active'));
                if (!(e.ctrlKey || e.metaKey || e.shiftKey)) {
                    $this.siblings('.active').removeClass('active');
                    that.multipleSelection = false;
                }
                descriptors = that.getDescriptors();
                filter = {
                    rename: false,
                    'delete': false,
                    copyTo: false,
                    moveTo: false
                };
                if (!(e.shiftKey)) {
                    that.lastClickeditem = null;
                } else {
                    that.lastClickeditem = pos;
                }
            }
            if (that.multipleSelection && that.getSelectionItemsCount() === $('.repository-navigator-tree ul li.item').length) {
                $('.repository-navigator-tree ul > li.header span.multiple-select i').addClass('clicked');
            }

            if (descriptors.length === 1 && descriptors[0].isFolder()) { // folder
                filter.versions = false;
                that.refreshCommandBar(descriptors, filter);
                if ($this.hasClass('active') && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
                    that.addOpenSaveBtn($this, true);
                }
            } else { // file
                that.refreshCommandBar(descriptors, filter);
                if (!(e.ctrlKey || e.metaKey || e.shiftKey)) {
                    that.addOpenSaveBtn($this);
                }
            }

            return false;
        });

        /*
         * Click on sort icons in headers
         */
        this.repositoryNavigator.on('click', '.repository-navigator-tree ul > li.header span.sortable-header i', function(e) {
            var sortDescClassName = "fa fa-sort-amount-desc";
            var sortAscClassName = "fa fa-sort-amount-asc";
            var sortClassName = "fa fa-sort";
            var iconClass = $(this).attr('class');
            var asc = iconClass !== sortAscClassName;
            var classes = $(this).parent().attr('class').split(' ');
            var ul = that.repositoryNavigator.find('.navigator-column > ul');
            if(classes.length > 0) {
                that.sortByHeaderColumns(ul, classes[0], asc);
                $('.repository-navigator-tree ul > li.header span.sortable-header i').attr('class',sortClassName);
                if(asc) {
                    $(this).attr('class',sortAscClassName);
                } else {
                    $(this).attr('class',sortDescClassName);
                }
            }
        });

        if (!this.readOnly) {
            /*
             * Click on select all.
             */
            this.repositoryNavigator.on('click', '.repository-navigator-tree ul > li.header span.multiple-select i', function(e) {
                that.removeOpenSaveBtn($('.repository-navigator-tree ul li.item.openSave').removeClass('openSave'));
                that.lastClickeditem = null;
                if ($(this).hasClass('clicked')) {
                    $(this).removeClass('clicked');
                    $('.repository-navigator-tree ul li.item').removeClass('active');
                    that.multipleSelection = false;
                } else {
                    $(this).addClass('clicked');
                    $('.repository-navigator-tree ul li.item').addClass('active');
                    that.multipleSelection = true;
                }
                that.refreshCommandBar(that.getDescriptors(), that.getFilter(that.getDescriptors()));

                return false;
            });

            /*
             * Click on item multiple-select button.
             */
            this.repositoryNavigator.on('click', '.repository-navigator-tree ul > li.item i.multiple-select', function(e) {
                $('body').children('div.tooltip').remove();
                var $item = $(this).closest('li.item');
                that.removeOpenSaveBtn($item.siblings('.openSave').removeClass('openSave'));
                that.removeOpenSaveBtn($item.removeClass('openSave'));

                if ($item.hasClass('active')) {
                    $item.removeClass('active');
                    that.lastClickeditem = null;
                    if (that.getSelectionItemsCount() === 0) {
                        that.multipleSelection = false;
                    }
                } else {
                    var pos = $item.attr('data-pos');
                    $item.addClass('active');
                    if (!that.lastClickeditem) {
                        that.lastClickeditem = pos;
                    }
                    if (e.shiftKey && that.lastClickeditem !== pos) {
                        if (that.lastClickeditem < pos) {
                            that.setSelectedItems(that.lastClickeditem, pos);
                        } else {
                            that.setSelectedItems(pos, that.lastClickeditem);
                        }
                    }
                    that.multipleSelection = true;
                }

                if (that.multipleSelection && that.getSelectionItemsCount() === $('.repository-navigator-tree ul li.item').length) {
                    $('.repository-navigator-tree ul > li.header span.multiple-select i').addClass('clicked');
                } else {
                    $('.repository-navigator-tree ul > li.header span.multiple-select i').removeClass('clicked');
                }

                var descriptors = that.getDescriptors();
                if (descriptors.length >= 1) {
                    that.refreshCommandBar(descriptors, that.getFilter(descriptors));
                }

                return false;
            });
        }

        /*
         * Click on a Repository
         */
        this.repositoryNavigator.on('click', '.navigator-repositories ul.repositories-column > li.item', function(e) {
            var $this = $(this);
            if (e.target.tagName === 'INPUT' && $this.hasClass('active')) { // Do not remove selection when clicking on a input
                return false;
            }

            var descriptor;
            that.removeOpenSaveBtn($this.siblings('.active').removeClass('active'));
            $this.addClass('active');
            descriptor = $this.data('descriptor');
            var filter = {
                rename: false,
                delete: false,
                copyTo: false,
                moveTo: false,
                versions: false,
                download: false
            };

            if (descriptor && typeof descriptor.isFolder === "function" && descriptor.isFolder()) {
                that.refreshCommandBar([descriptor], filter);
            } else {
                that.refreshCommandBar([descriptor]);
            }

            if (descriptor) {
                // load the right view
                var repository = $this.data('repository');
                $this.addClass('loading');
                that.loadNavigator(repository, descriptor, function() {
                    $this.removeClass('loading');
                }, function() {
                    $this.removeClass('loading');
                });
            } else {
                that.removeNavigator();
            }
            return false;
        });

        /*
         * Open a Folder or save/open a file
         */
        that.repositoryNavigator.on('click', '.repository-navigator-tree ul > li.item span.item-name', function(e) {
            //we don't want to open when we are doing a multiple selection.
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                $(this).closest('li.item').trigger($.Event('click', { shiftKey: e.shiftKey, metaKey: e.metaKey, ctrlKey: e.ctrlKey }));
                return false;
            }
            $('body').children('div.tooltip').remove();
            if (e.target.tagName === 'INPUT') { // we don't want to reload when the user rename the li
                return;
            }
            var $this = $(this);
            var li = $this.closest('li.item');
            var descriptor = li.data('descriptor');
            var column = $this.closest('.navigator-column');
            var currentRepository = column.data('repository') || li.data('repository');
            that.lastClickeditem = null;
            that.refreshCommandBar();

            if (descriptor && descriptor.isFolder()) { // folder
                li.addClass('loading');
                that.loadFolder(currentRepository, descriptor);
                return false;
            } else if (descriptor) { // file
                if (!li.hasClass('active')) {
                    li.siblings('.active').removeClass('active');
                    li.addClass('active');
                }
                li.addClass('loading');
                if (that.mode === "open") {
                    that.open(descriptor);
                } else {
                    that.save();
                }
                return false;
            } else {
                return true;
            }
        });

        /*
         * Keyboard navigation on the navigator pane
         */
        this.repositoryNavigator.on('keydown', '.repository-navigator-tree', function(e) {
            var $this = $(this);
            if ($this.find('li.loading').length) { // do not allow loading multiple folder at the same time
                return;
            }

            var li = $this.find('li[data-pos="' + that.lastClickeditem + '"]');
            var clickEvent = $.Event('click', { shiftKey: e.shiftKey });

            switch (e.which) {
                case 13: // enter
                    if (li.length) {
                        li.find('.item-name').click();
                    }
                    break;
                case 37: // left
                    if (li.length) {
                        $this.prev().focus();
                    }
                    break;
                case 38: // up
                    if (li.length) {
                        var prev = e.shiftKey && li.hasClass('active') && li.attr('data-pos') !== that.previous ? li : li.prev('li.item');
                        if (prev.length) {
                            prev.trigger(clickEvent);
                            that.previous = that.lastClickeditem;
                        } else {
                            that.lastClickeditem = $this.find('li.item:last').attr('data-pos');
                            $this.find('li.item:last').trigger(clickEvent);
                        }
                    } else {
                        $this.find('li.item:last').trigger(clickEvent);
                    }
                    break;
                case 40: // down
                    if (li.length) {
                        var next = li.next();
                        if (next.length) {
                            next.trigger(clickEvent);
                        } else {
                            that.lastClickeditem = 1;
                            $this.find('li.item:first').trigger(clickEvent);
                        }
                    } else {
                        $this.find('li.item:first').trigger(clickEvent);
                    }
                    break;
                case 8: // backspace
                    var locationBar = $this.find('.locationBar');
                    if (locationBar.children('.path').length) {
                        locationBar.children('.path:last').click();
                    }
                    break;
                default:
                    return true;
            }
            return false;
        });

        /*
         * Keyboard navigation on the repository pane
         */
        this.repositoryNavigator.on('keydown', '.navigator-repositories', function(e) {
            var key = e.which;
            var $this = $(this);
            var li = $this.find('li.active');
            if ($this.find('li.loading').length) { // do not allow loading multiple folder at the same time
                return;
            }

            switch (key) {
                case 13: // enter
                    if (li.length) {
                        li.click();
                    }
                    break;
                case 39: // right
                    if (li.length) {
                        $this.next().focus().find('li.item:first').click();
                    }
                    break;
                case 38: // up
                    if (li.length) {
                        var prev = li.prev();
                        if (prev.length) {
                            prev.click();
                        } else {
                            li.click();
                            $this.find('li:last').click();
                        }
                    }
                    break;
                case 40: // down
                    if (li.length) {
                        var next = li.next();
                        if (next.length) {
                            next.click();
                        } else {
                            li.click();
                        }
                    } else {
                        $this.find('li:first').click();
                    }
                    break;
            }
        });


        /*
         * Command bar commands
         */
        this.commands = {
            create: function(repository, descriptors, ul) {
            },
            browseFolder: function(repository, descriptors, ul) {
                that.loadFolder(repository, descriptors[0]);
            },
            rename: function(repository, descriptors, ul) {
                var li = ul.children('li.active');
                that.renameNavigatorLi(li, function(newName) {
                    if (newName !== descriptors[0].getName()) {
                        var btn = that.repositoryNavigator.find('button[command="rename"]').addClass('loading');
                        repository.rename({
                            descriptors: descriptors,
                            newName: newName,
                            rename: true,
                            success: function(newDescriptor) {
                                // Update the information of the folder
                                that.updateLi(repository, newDescriptor, li);
                                layoutColumns(ul);
                                if (!li.hasClass('active')) {
                                    li.click();
                                }
                                btn.removeClass('loading');
                            },
                            error: function(message) {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Rename Error'),
                                    'message': message
                                });
                                btn.removeClass('loading');
                            }
                        });
                    }
                });
            },
            'delete': function(repository, descriptors, ul) {
                var message = _('Are you sure you want to delete %d item?', { count: descriptors.length, sprintf: [descriptors.length] });

                if (descriptors.length === 1) {
                    message += '<br><b>' + descriptors[0].getName() + '</b>';
                }

                that.repositoryNavigator.userMessage({
                    'title': _('Confirm Delete'),
                    'message': message,
                    'buttons': [
                        {
                            'label': _('Ok'),
                            'callback': function() {
                                descriptors.forEach(function(descriptor) {
                                    var btn = that.repositoryNavigator.find('button[command="delete"]').addClass('loading');
                                    var li = that.getLiByDescriptor(descriptor);
                                    repository['delete']({
                                        descriptor: descriptor,
                                        success: function(type) {
                                            li.remove();
                                            if (ul.children('li.item').length === 0) {
                                                $('<li class="empty">' + ('This folder is empty.') + '</li>').appendTo(ul);
                                            }
                                            // get the previous selector and refresh the command bar
                                            var parentDescriptor = ul.parent('.navigator-column').data('descriptor');
                                            that.refreshCommandBar([parentDescriptor], {
                                                rename: false,
                                                'delete': false
                                            });
                                            btn.removeClass('loading');
                                        },
                                        error: function(error, type) {
                                            that.repositoryNavigator.userMessage({
                                                'title': _('Delete Folder Error'),
                                                'message': error
                                            });
                                            btn.removeClass('loading');
                                        }
                                    });
                                });
                            }
                        },
                        {
                            'label': _('Cancel')
                        }
                    ]
                });
            },
            upload: function(repository, descriptors, ul) {
                that.repositoryNavigator.userMessage({
                    title: _('Upload file'),
                    message: _('Select the file you want to upload:') + ' <input style="display:none;" id="upload" type="file"' + (that.allowedUploadFile.accept ? ' accept="' + that.allowedUploadFile.accept + '"' : '') + '/><button class="enabled" id="uploadBtn">' + _('Select a file') + '</button>',
                    buttons: [
                        {
                            label: _('Ok'),
                            callback: function() {
                                var selectedFile = $('#upload').get(0).files[0];
                                if (selectedFile && selectedFile.size <= 5242880*10) { // 50MB
                                    var btn = that.repositoryNavigator.find('button[command="upload"]').addClass('loading');
                                    var reader = new FileReader();

                                    reader.onload = function(e) {
                                        var text = reader.result;
                                        var nameWithoutExt = selectedFile.name.split('.')[selectedFile.name.split('.').length - 2] || selectedFile.name;
                                        repository.save({
                                            descriptor: descriptors[0],
                                            data: text,
                                            filename: nameWithoutExt,
                                            mimetype: that.allowedUploadFile.mimeType,
                                            success: function(descriptor) {
                                                // success
                                                btn.removeClass('loading');
                                                that.addLi(repository, descriptor, ul);
                                                layoutColumns(ul);
                                            },
                                            error: function(message) {
                                                // fail
                                                btn.removeClass('loading');
                                                that.repositoryNavigator.userMessage({
                                                    title: _('Error uploading file'),
                                                    message: message
                                                });
                                            }
                                        });
                                    };

                                    reader.onerror = function(e) {
                                        btn.removeClass('loading');
                                        that.repositoryNavigator.userMessage({
                                            title: _('Error reading file')
                                        });
                                    };

                                    reader.readAsText(selectedFile, 'utf-8');
                                } else {
                                    that.repositoryNavigator.userMessage({
                                        message: _('Error: File size is more than 5MB')
                                    });
                                }
                            }
                        },
                        {
                            label: _('Cancel')
                        }
                    ]
                });
                var okBtn = $('.user-message .user-message-buttons button:contains("Ok")').removeClass('enabled').attr('disabled', 'disabled');

                $('#uploadBtn').click(function(e) {
                    $('#upload').click();
                });
                $('#upload').on('change', function(e) {
                    var filename = this.files[0] && this.files[0].name;
                    if (filename) {
                        $('#uploadBtn').text('[' + filename + ']');
                        okBtn.addClass('enabled').removeAttr('disabled');
                    // For Chrome the change event is triggered when user clicks on Cancel button so we need to put back this initial text and disable the ok button
                    } else {
                        $('#uploadBtn').text(_('Select a file'));
                        okBtn.removeClass('enabled').attr('disabled', 'disabled');
                    }
                });
            },
            download: function(repository, descriptors, ul) {
                var btn = that.repositoryNavigator.find('button[command="download"]').addClass('loading');
                repository.load({
                    descriptor: descriptors[0],
                    success: function(content) {
                        var blob = new Blob([content], { type: that.allowedUploadFile.blob });
                        saveAs(blob, descriptors[0].getName() + that.allowedUploadFile.extension);
                        btn.removeClass('loading');
                    }
                });
            },
            versions: function(repository, descriptors, ul) {
                that.repositoryNavigator.versionsModal({
                    repository: repository,
                    descriptor: descriptors[0],
                    openCallback: function(version) {
                        that.open(version);
                    }
                });
            },
            copyTo: function(repository, descriptors, ul) {
                var currentfolderDescriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                var btn = that.repositoryNavigator.find('button[command="copyTo"]').addClass('loading');
                that.repositoryNavigator.find('.repository-navigator-commandbar').children().attr("disabled", true);
                var places = that.getPlacesRepositoriesAndDescriptors();

                that.repositoryNavigator.sidePanel({
                    repository: repository,
                    descriptor: currentfolderDescriptor,
                    selection: descriptors,
                    count: that.getSelectionItemsCount(),
                    places: places,
                    command: 'copy',
                    successCallback: function(folderDescriptor, fileDescriptors, newRepository) {
                        let promises = fileDescriptors.map(fileDescriptor => {
                            return new Promise((resolve, reject) => {
                                return that.loadFile(newRepository, folderDescriptor,fileDescriptor).then(resolve).catch(reject);
                            });
                        });

                        Promise.allSettled(promises).then((results) => {
                            var errorMessages = [];
                            var firstCopiedDescriptor = null;
                            results.forEach(function(result) {
                                if(result.status === "rejected") {
                                    errorMessages.push(result.reason);
                                } else if(result.value && !firstCopiedDescriptor)  {
                                    firstCopiedDescriptor = result.value;
                                }
                            });
                            if(errorMessages.length > 0) {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Copy to Error'),
                                    'message': that.showErrorMessages(errorMessages),
                                    'width': 500
                                });
                            }
                            // if there are copied items
                            if (firstCopiedDescriptor) {
                                that.loadNavigator(newRepository, folderDescriptor);
                                // Set the place to active
                                let $place = that.repositoryNavigator.find('.navigator-repositories ul.repositories-column > li.item').filter(function(){return $(this).data('repository') === newRepository;});
                                that.removeOpenSaveBtn($place.siblings('.active').removeClass('active'));
                                $place.addClass('active');
                            }
                            btn.removeClass('loading');
                        });
                    },
                    errorCallback: function() {
                        btn.removeClass('loading');
                        that.loadFolder(repository, currentfolderDescriptor);
                    }
                });

            },
            moveTo: function(repository, descriptors, ul) {
                var currentfolderDescriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                var btn = that.repositoryNavigator.find('button[command="moveTo"]').addClass('loading');
                that.repositoryNavigator.find('.repository-navigator-commandbar').children().attr("disabled", true);
                var places = that.getPlacesRepositoriesAndDescriptors(repository, descriptors);

                that.repositoryNavigator.sidePanel({
                    repository: repository,
                    descriptor: currentfolderDescriptor,
                    selection: descriptors,
                    count: that.getSelectionItemsCount(),
                    places: places,
                    command: 'move',
                    successCallback: function(folderDescriptor, fileDescriptors, newRepository) {
                        if (currentfolderDescriptor.getRepositoryPath() === folderDescriptor.getRepositoryPath() && newRepository === repository) {
                            that.repositoryNavigator.userMessage({
                                'title': _('Move to Error'),
                                'message': _("The item is already in this location", { count: that.getSelectionItemsCount() })
                            });
                            btn.removeClass('loading');
                        } else if (newRepository !== repository) {

                            let promises = fileDescriptors.map(fileDescriptor => {
                                return new Promise((resolve, reject) => {
                                    return that.loadFile(newRepository, folderDescriptor, fileDescriptor).then((descriptor) => {
                                        // Delete the file
                                        repository['delete']({
                                            descriptor: fileDescriptor,
                                            success: () => {
                                                resolve(descriptor);
                                            },
                                            error: reject
                                        });
                                    }).catch(reject);
                                });
                            });

                            Promise.allSettled(promises).then((results) => {
                                var errorMessages = [];
                                var firstMovedDescriptor = null;
                                results.forEach(function(result) {
                                    if(result.status === "rejected") {
                                        errorMessages.push(result.reason);
                                    } else if(result.value && !firstMovedDescriptor)  {
                                        firstMovedDescriptor = result.value;
                                    }
                                });
                                if(errorMessages.length > 0) {
                                    that.repositoryNavigator.userMessage({
                                        'title': _('Move to Error'),
                                        'message': that.showErrorMessages(errorMessages),
                                        'width': 500
                                    });
                                }
                                // if there are moved items
                                if (firstMovedDescriptor) {
                                    that.loadNavigator(newRepository, folderDescriptor);
                                    // Set the place to active
                                    let $place = that.repositoryNavigator.find('.navigator-repositories ul.repositories-column > li.item').filter(function(){return $(this).data('repository') === newRepository;});
                                    that.removeOpenSaveBtn($place.siblings('.active').removeClass('active'));
                                    $place.addClass('active');
                                }
                                btn.removeClass('loading');
                            });

                        } else {
                            repository.move({
                                descriptors: fileDescriptors,
                                path: folderDescriptor.getRepositoryPath(),
                                rename: false,
                                success: function() {
                                    btn.removeClass('loading');
                                    that.loadFolder(repository, currentfolderDescriptor);
                                },
                                error: function(message) {
                                    that.repositoryNavigator.userMessage({
                                        'title': _('Move to Error'),
                                        'message': message
                                    });
                                    btn.removeClass('loading');
                                }
                            });
                        }
                    },
                    errorCallback: function() {
                        btn.removeClass('loading');
                        that.loadFolder(repository, currentfolderDescriptor);
                    }
                });
            },
            restore: function(repository, descriptor, ul) {
                var currentfolderDescriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                var btn = that.repositoryNavigator.find('button[command="restore"]').addClass('loading');
                var mimetype = that.getMimetypeToRestore(that.mimetype);
                that.repositoryNavigator.find('.repository-navigator-commandbar').children().attr("disabled", true);

                that.repositoryNavigator.sidePanelRestoreFiles({
                    repository: repository,
                    mimetype: mimetype,
                    successCallback: function(descriptors) {
                        let promises = descriptors.map(descriptor => {
                            return new Promise ((resolve, reject) => {
                                return that.restoreFile(repository,descriptor).then(resolve).catch(reject);
                            });
                        });

                        Promise.allSettled(promises).then((results) => {
                            var errorMessages = [];
                            results.forEach(function(result) {
                                if(result.status === "rejected") {
                                    errorMessages.push(result.reason);
                                }
                            });
                            if(errorMessages.length > 0) {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Restore Error'),
                                    'message': that.showErrorMessages(errorMessages),
                                    'width': 500
                                });
                            }
                            btn.removeClass('loading');
                            that.loadFolder(repository, currentfolderDescriptor);
                        });
                    },
                    errorCallback: function() {
                        btn.removeClass('loading');
                        that.loadFolder(repository, currentfolderDescriptor);
                    }
                });
            },
            promote: function(repository, descriptor, ul) {
                var currentfolderDescriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                var btn = that.repositoryNavigator.find('button[command="promote"]').addClass('loading');
                that.repositoryNavigator.find('.repository-navigator-commandbar').children().attr("disabled", true);
                var places = that.getPlacesRepositoriesAndDescriptors();

                that.repositoryNavigator.sidePanelPromotePlace({
                    repository,
                    places,
                    successCallback: function(targetRepository, targetDescriptor, version = "", state = "") {
                        repository.promote({
                            target: targetRepository.repositoryId,
                            version,
                            state,
                            success: () => {
                                btn.removeClass('loading');
                                that.loadNavigator(targetRepository, targetDescriptor);
                                // Set the place to active
                                let $place = that.repositoryNavigator.find('.navigator-repositories ul.repositories-column > li.item').filter(function(){return $(this).data('repository') === targetRepository;});
                                that.removeOpenSaveBtn($place.siblings('.active').removeClass('active'));
                                $place.addClass('active');
                            },
                            error: (message) => {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Promote Error'),
                                    'message': message,
                                    'width': 500
                                });
                                btn.removeClass('loading');
                            }
                        });
                    },
                    errorCallback: function() {
                        btn.removeClass('loading');
                        that.loadNavigator(repository, currentfolderDescriptor);
                    }
                });
            }
        };

        /*
         * Places context menu commands
         */
        this.placesCommands = {
            create: function(ul, params) {
                var li = that.addRepositoryLi(new NewRepositoryDescriptor(), ul);
                li.addClass('active');

                that.renameRepositoryLi(li, function(newName) {
                    li.addClass('loading');

                    if (params) {
                        params.provider.addRepository({
                            name: newName,
                            id: params.descriptorId,
                            providerParameters: params.params,
                            success: function(instanceParameters) {
                                providerRegistry.register(params, instanceParameters, null /* the provider is already loaded */ , function(repository) {
                                    li.data('repository', repository);
                                    li.attr('data-type', repository.type);
                                    repository.getRepositoryNavigatorModel({
                                        success: function(descriptors) {
                                            if (descriptors.length > 0) {
                                                var descriptor = descriptors[0];
                                                li.data('descriptor', descriptor);
                                                li.find('.item-name').html(descriptor.getName());
                                                if (typeof repository.getImage === "function") {
                                                    li.find('i').attr('class', 'img-' + repository.getImage());
                                                } else {
                                                    li.find('i').attr('class', 'img-' + repository.type);
                                                }
                                                li.click();
                                                li.removeClass('loading');
                                            } else {
                                                li.remove();
                                            }
                                        },
                                        error: function(message) {
                                            that.repositoryNavigator.userMessage({
                                                'title': _('Error while loading the repository'),
                                                'message': message
                                            });
                                            li.remove();
                                        }
                                    });
                                }, true);
                            },
                            error: function(message) {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Create Repository Error'),
                                    'message': message
                                });
                                li.remove();
                                li.removeClass('loading');
                            }
                        });
                    }
                }, function() { // cancel
                    li.remove();
                });
            },
            rename: function(repository, descriptors, ul) {
                var li = ul.children('li.active');
                that.renameRepositoryLi(li, function(newName) {
                    if (newName !== descriptors[0].getName()) {
                        li.addClass('loading');
                        repository.renameRepository({
                            name: newName,
                            success: function(newRepository) { // {name, id, rights}
                                var descriptor = li.data('descriptor');
                                descriptor.setAttribute('name', newRepository.name);
                                li.find('.item-name').html(descriptor.getName());
                                if (!li.hasClass('active')) {
                                    li.click();
                                }
                                li.removeClass('loading');
                            },
                            error: function(message) {
                                that.repositoryNavigator.userMessage({
                                    'title': _('Rename Repository Error'),
                                    'message': message
                                });
                                li.removeClass('loading');
                            }
                        });
                    }
                });
            },
            'delete': function(repository, descriptors, ul) {
                var li = ul.children('li.active');
                that.repositoryNavigator.userMessage({
                    'title': _('Confirm Delete'),
                    'message': _('Are your sure that you want to delete:') + '<br><b>' + descriptors[0].getName() + '</b>',
                    'buttons': [
                        {
                            'label': _('Ok'),
                            'callback': function() {
                                li.addClass('loading');
                                repository.deleteRepository({
                                    success: function() {
                                        li.removeClass('loading');
                                        li.remove();
                                        providerRegistry.unregister(repository);
                                        that.refreshCommandBar();
                                        that.removeNavigator();
                                    },
                                    error: function(error, type) {
                                        that.repositoryNavigator.userMessage({
                                            'title': _('Delete Folder Error'),
                                            'message': error
                                        });
                                        li.removeClass('loading');
                                    }
                                });
                            }
                        },
                        {
                            'label': _('Cancel')
                        }
                    ]
                });
            },
            share: function(repository, descriptors, ul) {
                that.repositoryNavigator.shareModal({
                    repository: repository
                });
            },
            clone: function(repository, descriptors, ul) {
                var that = this;

                providerRegistry.getAllNew(function(providers) {
                    providers.forEach(function(provider) {
                        if (provider.type === repository.type) {
                            var params = {
                                ...provider,
                                descriptorId: repository.repositoryId
                            };
                            that.create(ul, params);
                            return false;
                        }
                    });
                });
            },
            issues: function(repositories, descriptor, ul){
                // Disable buttons until the use close the panel.
                that.repositoryNavigator.find('.repository-navigator-commandbar').children().attr("disabled", true);
                that.repositoryNavigator.sidePanelIssueEditor(repositories.repositoryId, that.getCurrentRepository().url, that.tinymceSkinUrl);
            },
            restore: function(repository, descriptor, ul) {
                that.commands.restore(repository, descriptor, ul);
            },
            promote: function(repository, descriptor, ul) {
                that.commands.promote(repository, descriptor, ul);
            }
        };

        /*
         * click handler for the commandbar
         */
        that.repositoryNavigator.on('click', 'div.repository-navigator-commandbar button.enabled', function(e) {
            var $this = $(this);
            var currentRepository = that.getCurrentRepository();
            var command = $this.attr('command');
            var descriptors = that.getDescriptors();
            var ul = that.repositoryNavigator.find('.navigator-column > ul');

            if (typeof that.commands[command] !== "undefined") {
                that.commands[command](currentRepository, descriptors, ul);
            }
        });

        /*
         * click handler for the open/save button
         */
        that.repositoryNavigator.on('click', '.navigator-column > ul > li button.enabled', function(e) {
            var $this = $(this);
            var $li = $this.closest('li');
            if ($this.hasClass('open')) {
                $li.addClass('loading');
                that.open($li.data("descriptor"));
            } else if ($this.hasClass('save')) {
                $li.addClass('loading');
                if (!$li.data('descriptor')) {
                    var descriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                    that.save(descriptor, $li.find('input').val());
                } else {
                    that.save();
                }
            } else if ($this.hasClass('openFolder')) {
                $li.find('.details > .item-name').click();
            }
            e.stopPropagation();
            return false;
        });

        /*
         * Right click (context menu) navigator
         */
        that.repositoryNavigator.on('contextmenu', '.navigator-column > ul, .navigator-column > ul > li', function(e) {
            var $this = $(this);
            var x = e.clientX;
            var y = e.clientY;
            var descriptors, ul, filter;
            if (e.target.tagName === "UL") {
                descriptors = that.getDescriptors();
                ul = $this;
                filter = {
                    rename: false,
                    'delete': false,
                    versions: false,
                    copyTo: false,
                    moveTo: false,
                    download: false
                };
                that.removeOpenSaveBtn(ul.children('li.active').removeClass('active'));
            } else { // li or element inside li
                var li = $this.closest('li');
                ul = li.closest('ul');

                if (!that.multipleSelection || !li.hasClass('active')) {
                    that.repositoryNavigator.find('.navigator-column li.header .multiple-select i').removeClass('clicked');
                    that.removeOpenSaveBtn(li.siblings('.active').removeClass('active'));
                    that.multipleSelection = false;
                }
                // active the li
                if (!li.hasClass('active')) {
                    li.addClass('active');
                }

                descriptors = that.getDescriptors();

                if (descriptors.length > 1) {
                    filter = {
                        create: true,
                        upload: false,
                        rename: false,
                        versions: false,
                        download: false
                    };
                } else {
                    filter = {
                        create: true,
                        upload: false
                    };
                    if (that.mode !== 'open' || descriptors[0].isFolder()) {
                        filter.versions = false;
                    }
                }
                filter.restore = false;
                filter.promote = false;
            }

            if (descriptors && descriptors.length === 1 && descriptors[0].isFolder && descriptors[0].isFolder()) {
                that.refreshCommandBar(descriptors, filter);
            } else {
                that.refreshCommandBar(descriptors);
            }

            // remove the default context menu if the can do something with the descriptor
            that.showContextMenu(descriptors, ul, x, y, filter);
            e.preventDefault();
            return false;
        });

        /*
         * Right click (context menu) Places
         */
        if (!this.readOnly) {
            that.repositoryNavigator.on('contextmenu', '.repositories-column, .repositories-column > li', function(e) {
                var $this = $(this);
                var x = e.clientX;
                var y = e.clientY;
                var descriptor, ul;
                if (e.target.tagName === "UL") {
                    descriptor = null;
                    ul = $this;

                } else { // li or element inside li
                    var li = $this.closest('li');
                    descriptor = li.data('descriptor');
                    ul = li.closest('ul');

                    // active the li
                    if (!li.hasClass('active')) {
                        li.click();
                    }

                }
                if (descriptor) {
                    $this.addClass('loading');
                    // remove the default context menu if the can do something with the descriptor
                    that.showPlacesContextMenu(descriptor, ul, x, y, function() {
                        $this.removeClass('loading');
                    });
                } else {
                    that.showAddRepositoryContextMenu(ul, x, y);
                }
                e.preventDefault();

                return false;
            });
        }

        /*
         * Binding search event
         */
        that.repositoryNavigator.on('input', '.repository-navigator .search-input', function() {
            // We are typing a new search, we debounce the result to ease up on the server load
            that.debounceUpdateSearch(this.value);
            if(!this.value) {
                that.loadFolder(that.getCurrentRepository(), that.getCurrentFolderDescriptor());    
            }
        });

        that.repositoryNavigator.on('click', '.repository-navigator .search-clear', function() {
            // The x in the search was clicked
            that.updateSearch('');
            if(!this.value) {
                that.loadFolder(that.getCurrentRepository(), that.getCurrentFolderDescriptor());    
            }
        });

        that.repositoryNavigator.on('focus', '.repository-navigator .search-input', function() {
            // When loading without a current repository, select the first one to give us a focus
            if(!that.getCurrentRepository()) {
                that.repositoryNavigator.find('.navigator-repositories .repositories-column > li[data-type="publicapi"]').first().click();
                that.globalSearch = true;
            }
        });
        that.repositoryNavigator.on('click', '.search-more', function() {          
            // Initiate global search
            that.activateGlobalSearch();
        });

        that.repositoryNavigator.on('keyup', '.repository-navigator .search-input', function(e) {
            if (e.keyCode === 13) { // Enter
                that.debounceUpdateSearch.flush();
            } else if (e.keyCode === 27) { // Escape
                that.debounceUpdateSearch.cancel();
                that.updateSearch('');
                that.loadFolder(that.getCurrentRepository(), that.getCurrentFolderDescriptor());
            }
        });


        if (!this.readOnly) {
            that.repositoryNavigator.on('click', '.add-repository', function(e) {
                var x = e.clientX;
                var y = e.clientY;
                var ul = $(this).closest('ul');
                that.showAddRepositoryContextMenu(ul, x, y);

                return false;
            });
        }

        providerRegistry.getAll(function(providers) {
            var displayedProviders = [];
            if (that.providerFilterFn) {
                // User has specified a filter to determine the provider he wants, lets only add those
                for (var i = 0; i < providers.length; i++) {
                    if (that.providerFilterFn(providers[i]) === true) {
                        displayedProviders.push(providers[i]);
                    }
                }
            } else {
                displayedProviders = providers;
            }
            /*
             * show an error message if there is no provider available
             */
            if (displayedProviders.length === 0) {
                this.repositoryNavigator.userMessage({
                    title: _('No repository'),
                    message: _('No repositories are currently accessible for you.'),
                    buttons: [{
                        label: _('Ok'),
                        callback: function() {
                            that.callbackCancel();
                        }
                    }]
                });
            }

            /*
             * load repository ul
             */
            this.loadHome(displayedProviders);

            if (this.selection) {
                this.setSelection(this.selection);
            }
        }.bind(this));

        return this;
    }

    getNewFileOptions(parent, maxWidth) {
        let that = this;
        let createFile = function(repository, descriptors, ul, mimetype) {
            let oldDescriptor = descriptors[0];
            var descriptor = repository.reviveDescriptor({attributes: {
                ...oldDescriptor.getAttributes(),
                name: _("New Model"),
                mimetype: mimetype,
                isFolder: false
            }});
    
            var li = that.addLi(repository, descriptor, ul);
            that.scrollToBottom(li);
            li.addClass('active');
            that.removeOpenSaveBtn(li.siblings('.active').removeClass('active'));
            layoutColumns(ul);
    
            that.renameNavigatorLi(li, function(newName) {
                descriptor.setAttribute("name", newName);
                
                var nameExist = false;
                that.repositoryNavigator.find('.navigator-column > ul > li.item').each(function() {
                    var $this = $(this);
                    var des = $this.data('descriptor');
                    if (!$this.hasClass('active') && des && des.attributes.name === descriptor.attributes.name && des.attributes.mimetype === descriptor.attributes.mimetype) {
                        nameExist = true;
                        return false;
                    }
                });

                if (nameExist) {
                    that.repositoryNavigator.userMessage({
                        'title': _('Create File Error'),
                        'message': _('The name you have entered is already in use')
                    });
                    li.remove();
                } else {
                    var btn = that.repositoryNavigator.find('button[command="create"]').addClass('loading');
                    repository.save({
                        descriptor: descriptor,
                        success: (newDescriptor) => {
                            // Update the information of the folder
                            that.updateLi(repository, newDescriptor, li);
                            layoutColumns(ul);
                            li.click();
                            btn.removeClass('loading');
                        },
                        error: (message) => {
                            that.repositoryNavigator.userMessage({
                                'title': _('Create File Error'),
                                'message': message
                            });
                            li.remove();
                            btn.removeClass('loading');
                        }
                    });
                }
            }, function() { // cancel
                li.remove();
            });
        };
        let createFolder = function(repository, descriptors, ul) {
            var li = that.addLi(repository, new NewFolderDescriptor(), ul);
            that.scrollToBottom(li);
            li.addClass('active');
            layoutColumns(ul);

            that.renameNavigatorLi(li, function(newName) {
                var btn = that.repositoryNavigator.find('button[command="create"]').addClass('loading');
                repository.newFolder({
                    descriptor: descriptors[0],
                    newFolderName: newName,
                    success: function(newDescriptor) {
                        // Update the information of the folder
                        that.updateLi(repository, newDescriptor, li);
                        layoutColumns(ul);
                        li.click();
                        btn.removeClass('loading');
                    },
                    error: function(message) {
                        that.repositoryNavigator.userMessage({
                            'title': _('Create Folder Error'),
                            'message': message
                        });
                        li.remove();
                        btn.removeClass('loading');
                    }
                });
            }, function() { // cancel
                li.remove();
            });
        };

        let menu = $('<div class="dropdown-content repository-navigator-contextual-menu"></div>').appendTo(parent);
        let menuUl = $('<ul></ul>').appendTo(menu);

        let menuLi = $('<li class="item" data-mimetype="folder"></li>').appendTo(menuUl);
        $('<span class="item-name"><i class="img-folder"></i><span class="name">' + _('Folder') + '</span></span>').appendTo(menuLi);

        menuLi.addClass('enabled');

        for (let mimetype of this.mimetypeWithTemplate) {
            menuLi = $('<li class="item" data-mimetype="' + mimetype.name + '"></li>').appendTo(menuUl);
            $('<span class="item-name"><i class="img-file"></i><span class="name">' + _("%s Model", mimetype.label) + '</span></span>').appendTo(menuLi);
            menuLi.addClass('enabled');
        }

        if (typeof maxWidth === "number" && menu.width() > maxWidth) {
            menu.addClass("onLeft");
        }

        menu.on('click', 'li.enabled[data-mimetype]', function(e) {
            var $this = $(this);
            var currentRepository = that.getCurrentRepository();
            var mimetype = $this.attr('data-mimetype');
            var descriptors = that.getDescriptors();
            var ul = that.repositoryNavigator.find('.navigator-column > ul');
            if (typeof mimetype !== "undefined") {
                if (mimetype === "folder") {
                    createFolder(currentRepository, descriptors, ul);
                } else {
                    createFile(currentRepository, descriptors, ul, mimetype);
                }
                parent.removeClass("hover");
            }
        });

        return menu;
    }

    scrollToBottom(li) {
        var $container = li.closest('.repository-navigator-container');
        $container.animate({ scrollTop: $container[0].scrollHeight }, 500);
    }

    addOpenSaveBtn(li, isFolder) {
        li.addClass('openSave');
        var btn;
        if (li.hasClass('saveAs')) {
            var input = li.find('input');
            if (input.val()) {
                li.find('button').addClass('enabled');
            }
        } else if (!isFolder) {
            if (this.mode === "save") {
                this.scrollToBottom(li);
            }
            btn = $('<button class="enabled ' + this.mode + '">' + (this.buttonName || (this.mode === "open" ? _("Open") : _("Save"))) + '</button>');
            li.append(btn);
        } else {
            btn = $('<button class="enabled openFolder">' + ('Open Folder') + '</button>');
            li.append(btn);
        }

        var $container = li.closest('.repository-navigator-container');
        var visibleHeight = $container.height();
        var itemOffset = li.offset().top - $container.offset().top;
        if (itemOffset < 0) {
            $container.animate({ scrollTop: $container.scrollTop() + itemOffset }, 500);
        } else if (itemOffset + li.outerHeight() > visibleHeight) {
            $container.animate({ scrollTop: $container.scrollTop() + (itemOffset + li.outerHeight() - visibleHeight) }, 500);
        }
    }
    
    updateSearch(term) {

        if(!this.currentSearchTerm && term) {
            // Entering search mode
            this.repositoryNavigator.find('.repository-navigator').addClass('search');
        } else if(this.currentSearchTerm && !term) {
            // Leaving search mode
            this.globalSearch = false;
            this.repositoryNavigator.find('.repository-navigator').removeClass('globalSearch').removeClass('search');            
            this.repositoryNavigator.find('.search-input').val("");
        }

        this.currentSearchTerm = term;
        
        var column = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');
        var repository = this.getCurrentRepository();
        var descriptor = this.getCurrentFolderDescriptor();

        if (term && term.length > 0) { 
            var that = this; 
            this.repositoryNavigator.find('.locationBar').addClass('loading');
            repository.searchFolderContent({
                mimetype: that.mimetype,
                search: term,
                global: that.globalSearch,
                success: function(children) {
                    that.loadSearchChildren(repository, children, column);
                    that.refreshCommandBar([descriptor], {
                        browseFolder: false,
                        'delete': false,
                        rename: false,
                        copyTo: false,
                        moveTo: false,
                        versions: false,
                        download: false
                    });
                    that.updateStatusBar(repository, descriptor);
                    that.repositoryNavigator.find('.locationBar').removeClass('loading');
                },
                error: function(message) {
                    that.repositoryNavigator.userMessage({
                        'title': _('Search Folder Content Error'),
                        'message': message
                    });
                    that.repositoryNavigator.find('.locationBar').removeClass('loading');
                }
            });
        }

    }

    removeOpenSaveBtn(li) {
        if (!li.hasClass('saveAs')) {
            li.find('button').remove();
        }
        li.removeClass('openSave');
    }

    loadHome(providers) {
        var that = this;
        var div = this.repositoryNavigator.find('.navigator-repositories');
        var ul = div.children('ul.repositories-column');
        if (ul.length === 0) {
            var repositoriesBrowser = $('<div class="repositories-browser"></div>').appendTo(div);
            ul = $('<ul class="repositories-column"></ul>').appendTo(repositoriesBrowser);
        }
        providers.forEach(function(repository) {
            var li = $('<li class="loading item" data-type="' + repository.type + '"></li>').appendTo(ul);
            li.data('repository', repository);
            repository.getRepositoryNavigatorModel({
                success: function(descriptors) {
                    if (descriptors.length > 0) {
                        var descriptor = descriptors[0]; // TODO: add support for multiple repository from the same provider
                        that.addRepositoryLi(descriptor, ul, li);
                    } else {
                        li.remove();
                    }
                },
                error: function(message) {
                    that.repositoryNavigator.userMessage({
                        'title': _('Error while loading the repository'),
                        'message': message
                    });
                    li.remove();
                }
            });
        }, this);

        if (!this.readOnly) {
            providerRegistry.getAllNew(function(providers) {
                if (providers.length) {
                    ul.append('<li class="add-repository"><i class="add-repository-icon" style="font-size: 24px"></i></li>');
                }
            });
        }
    }

    addRepositoryLi(descriptor, ul, li) {
        var icon = '';
        if (li) {
            var repository = li.data('repository');
            if (typeof repository.getImage === "function") {
                icon = 'img-' + repository.getImage();
            } else {
                icon = 'img-' + repository.type;
            }
        }
        if (!li) {
            li = $('<li class="item" data-type="' + descriptor.repositoryType + '"></li>');
            var addRepository = ul.find('.add-repository');
            if (addRepository.length) {
                li.insertBefore(addRepository);
            } else {
                li.appendTo(ul);
            }
        }

        li.append('<i class="' + icon + '"></i>').removeClass('loading');
        li.append('<span class="item-name">' + (descriptor.getName && descriptor.getName()) + '</span>');
        li.data('descriptor', descriptor);
        return li;
    }

    addLi(repository, descriptor, ul, li) {
        // first remove empty statement
        ul.children('li.empty').remove();
        var icon;
        if (descriptor.isFolder()) {
            icon = 'img-folder';
        } else {
            icon = 'img-file';
        }

        if (!li) {
            var saveAs = ul.children('.saveAs');
            var mimetype;
            if (descriptor.getMimeType) {
                mimetype = descriptor.getMimeType();
            }
            if (!mimetype && descriptor.isFolder()) {
                mimetype = 'folder';
            }
            if (!mimetype) {
                mimetype = 'application/octet-stream';
            }

            if (saveAs.length) {
                li = $('<li class="item" data-mimetype="' + mimetype + '"></li>').insertBefore(saveAs);
            } else {
                li = $('<li class="item" data-mimetype="' + mimetype + '"></li>').appendTo(ul);
            }
        }


        li.removeClass('loading');
        var multipleSelect = (!this.readOnly ? '<span class="item-multiple-select no-resizable"><i class="multiple-select"></i></span>' : '');
        var html = '<div class="details">' +
            multipleSelect +
            '  <span class="item-name" data-toggle="tooltip"><i class="' + icon + '"></i><span class="name"></span></span>' +
            '  <span class="lastUpdated" data-toggle="tooltip"></span>' +
            '  <span class="updatedBy" data-toggle="tooltip"></span>';

        var extraHeaders = [];
        if (typeof(repository.getExtraColumns) === 'function') {
            extraHeaders = repository.getExtraColumns(this.currentSearchTerm);
        }
        for (var i = 0; i < extraHeaders.length; i++) {
            html += '  <span class="extraColumn extraColumn-' + i + '" data-toggle="tooltip" data-column-name="' + extraHeaders[i] + '"></span>';
        }
        html += '</div>';
        li.attr('data-pos', ul.find('li.item').length);
        $(html).appendTo(li);

        this.updateLi(repository, descriptor, li);
        return li;
    }

    /*
     * this function is used by rename and New Folder to update the li
     */
    updateLi(repository, descriptor, li) {
        li.data('descriptor', descriptor);
        // name
        li.find('> .details > .item-name > .name').text(descriptor.getName())
            .attr('title', descriptor.getName())
            .attr('data-toggle', 'tooltip');
        // last updated
        var updated = descriptor.getUpdated && descriptor.getUpdated();
        if (typeof updated === "object") {
            if (isNaN(updated.getTime())) {
                updated = null;
            } else {
                updated = dateToString(updated);
            }
        }
        li.find('> .details > .lastUpdated').text(updated || '-')
            .attr('title', updated || '-')
            .attr('data-toggle', 'tooltip');
        // updated by
        li.find('> .details > .updatedBy').text(descriptor.getUpdater && descriptor.getUpdater() || '-')
            .attr('title', descriptor.getUpdater && descriptor.getUpdater() || '-')
            .attr('data-toggle', 'tooltip');

        //extra columns
        li.find('> .details > .extraColumn').each(function(index, el) {
            var value = repository.getExtraColumnValue($(el).attr('data-column-name'), descriptor);
            if (!value) {
                value = '-';
            }
            $(el).text(value).attr('title', value).attr('data-toggle', 'tooltip');
        });
    }

    /*
     * sort by the header's class name
     */
    sortByHeaderColumns(ul, header, asc) {
        asc = asc ? 1 : -1;
        var items = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column > ul > li.item');
        if(header === 'name') {
            this.sortColumnByName(items, asc).appendTo(ul);
        } else if (header === 'lastUpdated') {
            this.sortColumnByLastUpdated(items, asc).appendTo(ul);
        } else if (header === 'updatedBy') {
            this.sortColumnByUpdater(items, asc).appendTo(ul);
        } else if (header === 'version') {
            this.sortColumnByVersion(items, asc).appendTo(ul);
        } else if (header === 'state') {
            this.sortColumnByState(items, asc).appendTo(ul);
        } else if (header === 'path') {
            this.sortColumnByPath(items, asc).appendTo(ul);
        } else {
            return false;
        }
        ul.find('.search-more').remove().appendTo(ul);
    }

    /*
     * Check if one of the two li items to compare is the saveAs form. If true it should always be at the bottom of the li items
     */
    isSaveAsItem(a, b) {
        if($(a).attr('class').indexOf('saveAs') !== -1) {
            return 1;
        } else if($(b).attr('class').indexOf('saveAs') !== -1) {
            return -1;
        }
        return false;
    }

    sortColumnByName(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                return asc * d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    sortColumnByLastUpdated(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                if(d1.getAttribute('updated') && d2.getAttribute('updated')) {
                    return asc * (new Date(d2.getAttribute('updated')) - new Date(d1.getAttribute('updated')));
                } else if(d1.getAttribute('updated') || d2.getAttribute('updated')){
                    return d1.getAttribute('updated') ? -1 : 1;
                } else {
                    return d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
                }
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    sortColumnByUpdater(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                if(d1.getUpdater() && d2.getUpdater()) {
                    return asc * d1.getUpdater().toLowerCase().localeCompare(d2.getUpdater().toLowerCase());
                } else if(d1.getUpdater() || d2.getUpdater()) {
                    return d1.getUpdater() ? -1 : 1;
                } else {
                    return d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
                }
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    sortColumnByVersion(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                var d1Version = d1.getAttribute('version');
                var d2Version = d2.getAttribute('version');
                if(d1Version && d2Version) {
                    var d1ConformSemver = that.conformToSemver(d1Version);
                    var d2ConformSemver = that.conformToSemver(d2Version);
                    if(d1ConformSemver && d2ConformSemver) {
                        return asc * that.versionComparator(d1Version, d2Version);
                    } else if (d1ConformSemver || d2ConformSemver) {
                        return d1ConformSemver ? -1 : 1;
                    } else {
                        return d1Version.localeCompare(d2Version);
                    }
                } else if(d1Version || d2Version) {
                    return d1Version ? -1 : 1;
                } else {
                    return d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
                }
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    /*
     * Check if version format is X.Y.Z where X,Y and Z are numbers
     */
    conformToSemver(v) {
        var tab = v.split('.');
        if(tab.length > 3) {
            return false;
        }
        var isConform = true;
        tab.forEach(function(value) {
            if(isNaN(parseInt(value))) {
                isConform = false;
            }
        });
        return isConform;
    }

    versionComparator(v1, v2) {
        var a = v1.split('.').map(function(num) {
            return parseInt(num);
        });

        var b = v2.split('.').map(function(num) {
            return parseInt(num);
        });

        for (var i = 0; i < a.length && i < b.length; i++) {
            if(a[i] === b[i]) {
                continue;
            }
            return a[i] - b[i];
        }

        return a.length - b.length;
    }

    sortColumnByState(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                if(d1.getAttribute('state') && d2.getAttribute('state')) {
                    return asc * d1.getAttribute('state').toLowerCase().localeCompare(d2.getAttribute('state').toLowerCase());
                } else if(d1.getAttribute('state') || d2.getAttribute('state')) {
                    return d1.getAttribute('state') ? -1 : 1;
                } else {
                    return d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
                }
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    sortColumnByPath(items, asc) {
        var that = this;
        return items.sort(function (a,b) {
            var isSaveAs = that.isSaveAsItem(a,b);
            if (isSaveAs) {
                return isSaveAs;
            }
            var d1 = $(a).data('descriptor');
            var d2 = $(b).data('descriptor');
            if (d1.isFolder() === d2.isFolder()) {
                // if d1 and d2 have the same type
                if(d1.getAttribute('path') && d2.getAttribute('path')) {
                    var d1path = d1.getAttribute('path').split('/');
                    var d2path = d2.getAttribute('path').split('/');
                    for(var i =0; i < d1path.length; i++) {
                        if(d2path.length <= i) {
                            return asc;
                        }
                        if(d1path[i].toLowerCase().localeCompare(d2path[i].toLowerCase()) !== 0) {
                            return asc * d1path[i].toLowerCase().localeCompare(d2path[i].toLowerCase());
                        }
                    }
                    return 0;
                } else if(d1.getAttribute('path') || d2.getAttribute('path')) {
                    return d1.getAttribute('path') ? -1 : 1;
                } else {
                    return d1.getName().toLowerCase().localeCompare(d2.getName().toLowerCase());
                }
            } else {
                return d1.isFolder() ? -1 : 1;
            }
        });
    }

    /*
     * inline edit functionality
     */
    renameSpan(span, success, cancel) {
        var text = span.text();
        var input = $('<input type="text"/>').val(text);
        var editSpan = $('<span class="edit"></span>').append(input);
        if (span.css('max-width')) {
            var maxWidth = span.css('max-width');
            editSpan.css('max-width', maxWidth);
            editSpan.css('width', maxWidth);
        }
        var copy = span.clone();
        span.replaceWith(editSpan);

        var updateText = function(save) {
            input.unbind('.renameLi');
            if (save) {
                var newText = input.val();
                editSpan.closest('[tabindex]').focus();
                editSpan.replaceWith(copy.text(text));
                if (typeof success === "function") {
                    success(newText);
                }
            } else {
                editSpan.closest('[tabindex]').focus();
                editSpan.replaceWith(copy.text(text));
                if (typeof cancel === "function") {
                    cancel();
                }
            }
        };

        input.bind('focus.renameLi', function() {
            var that = this;
            setTimeout(function() {
                that.select();
            }, 10);
        });

        input.focus();
        input.bind('keydown.renameLi', function(e) {
            if (e.keyCode === 13) { // enter
                updateText(true);
                return false;
            } else if (e.keyCode === 27) { // esc
                updateText(false);
                return false;
            }
            e.stopPropagation();
            return true;
        });

        input.bind('blur.renameLi', function(e) {
            updateText(true);
        });
    }

    /*
     * inline edit for files/folders
     */
    renameNavigatorLi(li, success, cancel) {
        this.renameSpan(li.find('.details > span.item-name > .name'), success, cancel);
    }

    /*
     * inline edit for repository
     */
    renameRepositoryLi(li, success, cancel) {
        this.renameSpan(li.children('span.item-name'), success, cancel);
    }

    addHeader(repository, ul) {
        var extraHeaders = [];
        if (typeof(repository.getExtraColumns) === 'function') {
            extraHeaders = repository.getExtraColumns(this.currentSearchTerm);
        }
        var multipleSelect = (!this.readOnly ? '<span class="multiple-select no-resizable" title="' + _('Select all') + '" data-toggle="tooltip"><i></i></span>' : "");
        var html = '<li class="header">' +
            '  <div class="details">' +
            multipleSelect +
            '    <span class="name sortable-header" title="' + _('Name') + '" data-toggle="tooltip">' + _('Name') + '<i class="fa fa-sort-amount-asc"></i></span>' +
            '    <span class="lastUpdated sortable-header" title="' + _('Last Updated') + '" data-toggle="tooltip">' + _('Last Updated') + '<i class="fa fa-sort"></i></span>' +
            '    <span class="updatedBy sortable-header" title="' + _('Updated by') + '" data-toggle="tooltip">' + _('Updated by') + '<i class="fa fa-sort"></i></span>';
        for (var i = 0; i < extraHeaders.length; i++) {
            html += '<span class="' + extraHeaders[i].toLowerCase() + ' sortable-header extraColumn-' + i + '" title="' + _(extraHeaders[i]) + '" data-toggle="tooltip" data-column-name="' + extraHeaders[i] + '">' + _(extraHeaders[i]) + '<i class="fa fa-sort"></i></span>';
        }
        html += '  </div>' +
            '</li>';
        return $(html.replace(/>\s+</g, '><')).appendTo(ul);
    }

    addFooter(ul) {
        var that = this;
        var li = $('<li class="item saveAs"></li>').appendTo(ul);
        var details = $('<div class="details"></div>').appendTo(li);
        var itemName = $('<span class="item-name edit"></span>').appendTo(details);
        var btnSpan = $('<span class="saveAsBtn"></span>').appendTo(details);
        var btn = $('<button class="' + this.mode + '">' + (this.buttonName || (this.mode === "open" ? _("Open") : _("Save"))) + '</button>').appendTo(btnSpan);
        var input = $('<input type="text" placeholder="' + _('Select a name') + '"/>').appendTo(itemName);

        if (this.nameSuggestion) {
            input.val(this.nameSuggestion);
            li.find('button').addClass('enabled');
        }

        // remove navigation binding
        input.bind('keydown', function(e) {
            var $this = $(this);
            if (e.keyCode === 13 && $this.val() !== '') {
                var descriptor = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column').data('descriptor');
                that.save(descriptor, $this.val());
                return false;
            }

            var part1 = $this.val().slice(0, e.target.selectionStart);
            var part2 = $this.val().slice(e.target.selectionEnd);
            var value = String.fromCharCode(e.which);

            if (e.keyCode === 46 || e.keyCode === 8) { // delete and backspace
                if (e.target.selectionStart === e.target.selectionEnd) { // else only the selection is removed
                    if (e.keyCode === 46) { // delete
                        if (part2.length > 0) {
                            part2 = part2.slice(1);
                        }
                    } else if (e.keyCode === 8) { // backspace
                        if (part1.length > 0) {
                            part1 = part1.slice(0, part1.length - 1);
                        }
                    }
                }
                value = part1 + part2;
            }

            if (value !== '') {
                btn.addClass('enabled');
            } else {
                btn.removeClass('enabled');
            }

            e.stopPropagation();
            return true;
        });

        // scrolling to top to be sure that the footer is always visible
        ul.animate({
            scrollTop: li.offset().top
        }, 2000);

        return li;
    }

    /*
     * Fonction that create tree
     */
    loadChildren(repository, children, column) {
        if(this.currentSearchTerm) {
            this.currentSearchTerm = '';
            this.repositoryNavigator.find('.repository-navigator .search-input').val('');
        }
        var ul = column.children('ul').empty();
        this.addHeader(repository, ul);

        children = children.filter(function(a) {
            return a.getName();
        }).sort(function(a, b) { // sort asc
            if (a.isFolder() !== b.isFolder()) {
                return a.isFolder() ? -1 : 1;
            }
            return a.getName().toLowerCase().localeCompare(b.getName().toLowerCase());
        });

        children.forEach(function(child) {
            this.addLi(repository, child, ul);
        }, this);
        if (children.length === 0) {
            $('<li class="empty">' +  _('This folder is empty.') + '</li>').appendTo(ul);
        }

        layoutColumns(ul);

        if (this.mode === "save") {
            this.addFooter(ul);
        }
    }

    loadSearchChildren(repository, children, column) {
        var ul = column.children('ul').empty();
        this.addHeader(repository, ul);

        if(!this.globalSearch) {
            children = children.filter(function(a) {
                return a.getName();
            }).sort(function(a, b) { // sort asc
                if (a.isFolder() !== b.isFolder()) {
                    return a.isFolder() ? -1 : 1;
                }
                return a.getName().toLowerCase().localeCompare(b.getName().toLowerCase());
            });
        } else {
            children = children.filter(function(a) {
                return a.getName();
            }).sort(function(a, b) { // sort asc
                var aName = a.repository.name;
                console.log(a.repository);
                if(!aName) {
                    aName = a.repository.repositoryId;
                }
                var bName = b.repository.name;
                if(!bName) {
                    bName = b.repository.repositoryId;
                }
                var repoNameCompare = aName.toLowerCase().localeCompare(bName.toLowerCase());
                if(repoNameCompare !== 0) {
                    return repoNameCompare;
                }
                if (a.isFolder() !== b.isFolder()) {
                    return a.isFolder() ? -1 : 1;
                }
                return a.getName().toLowerCase().localeCompare(b.getName().toLowerCase());
            });
        }
        var currentRepoName = undefined;
        var that = this;
        children.forEach(function(child) {
            var name = child.repository.name;
            if(!name) {
                name = child.repository.repositoryId;
            }
            if(that.globalSearch &&  name !== currentRepoName) {
                currentRepoName = name;
                $('<li class="global-search-repo-name"/>').text(currentRepoName).appendTo(ul);
            }
            this.addLi(repository, child, ul);
        }, this);
        if (children.length === 0) {
            $('<li class="empty">' + _("Empty search results.") + '</li>').appendTo(ul);
        }

        layoutColumns(ul);
        if(!this.globalSearch) {
            $('<div class="search-more">' + _('More results from other places...') + '</div>').appendTo(ul);
        }
    }

    activateGlobalSearch() {
        this.globalSearch = true;
        this.repositoryNavigator.find('.repository-navigator').addClass('globalSearch');
        this.updateSearch(this.currentSearchTerm);
    }

    /*
     * Update the top status bar
     */
    updateStatusBar(repository, descriptor) {
        descriptor = descriptor || {};
        var status = this.repositoryNavigator.find('.repository-navigator-status');
        if(!this.currentSearchTerm) {
            var name = status.children('.folderName');
            var location = status.children('.locationBar').empty();
            location.data('repository', repository);

            name.html((descriptor.getName && descriptor.getName()) || _('Home'));
            var paths = (descriptor.getPath && descriptor.getPath().split('/').filter(function(a) {
                return a !== "";
            })) || [];
            if (repository) {
                paths.unshift(repository.getName());
            }

            paths.forEach(function(path, index) {
                if (path !== "") {
                    var lastPath = index === paths.length - 1;
                    $('<span' + (!lastPath ? ' class="path"' : '') + '>' + path + '</span>' +
                            (!lastPath ? '<span class="path-separator"></span>' : ''))
                        .appendTo(location)
                        .data('path', '/' + paths.slice(0, index + 1).join('/'));
                }
            });
        } else {
            if(this.globalSearch) { 
                status.children('.folderName').text(_('Global Search'));
            } else {
                status.children('.folderName').text((descriptor.getName && descriptor.getName()) || _('Home'));
            }
            
            $('<span/>').text(_('Search') + ': ' + this.currentSearchTerm).appendTo(status.children('.locationBar').empty());
        }
    }

    loadFolder(repository, descriptor, successCallback, errorCallback) {
        if (descriptor && !this.getCurrentRepository().canHandle(descriptor)) {
            //Cross repository linking from a search, we need to find the right repository
            this.repositoryNavigator.find('.navigator-repositories .repositories-column > li.active').removeClass('active');
            this.repositoryNavigator.find('.navigator-repositories .repositories-column > li').each(function() {
                var repoLi = $(this);
                var repo = repoLi.data('repository');
                if(repo && repo.canHandle(descriptor)) {
                    repoLi.addClass('active');
                    repoLi.parents('.repositories-column').scrollTop(repoLi.offset().top);
                    repository = repo;
                    return false;
                }
                return true;
            });
        }
        var that = this;
        var column = that.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');
        if(this.mode === "open" && repository.hasSearchSupport && repository.hasSearchSupport()) {
            that.repositoryNavigator.find('.repository-navigator-commandbar .search').show();
        } else {
            that.repositoryNavigator.find('.repository-navigator-commandbar .search').hide();
        }
        if (this.currentSearchTerm) {
            this.updateSearch('');
        }

        repository.getFolderContent({
            descriptor: descriptor,
            mimetype: that.mimetype,
            success: function(children) {
                that.loadChildren(repository, children, column);
                that.refreshCommandBar([descriptor], {
                    browseFolder: false,
                    'delete': false,
                    rename: false,
                    copyTo: false,
                    moveTo: false,
                    versions: false,
                    download: false
                });
                that.updateStatusBar(repository, descriptor);
                if(repository.type === 'local') {
                    that.localStorageDeprecation(column.find('ul'));
                }
                column.data('descriptor', descriptor);
                if (!column.data('repository')) {
                    column.data('repository', repository);
                }
                if (typeof successCallback === "function") {
                    successCallback();
                }
            },
            error: function(message) {
                that.repositoryNavigator.userMessage({
                    'title': _('Get Folder Content Error'),
                    'message': message
                });
                if (typeof errorCallback === "function") {
                    errorCallback();
                }
            }
        });
    }

    localStorageDeprecation(into) {
        into.prepend('<div style="margin: 10px;padding: 20px;background-color:orange;border: 1px solid black;"><b>Deprecation Notice.</b> The Browser Internal Storage feature will be removed from the product on the 1st of February 2024. Please save your work to another place.</div>');
    }

    async loadFile(repository, folderDescriptor, fileDescriptor) {
        return new Promise((resolve, reject) => {
            repository.load({
                descriptor: fileDescriptor,
                success: function(content, descriptor) {
                    descriptor.setAttribute('path', folderDescriptor.getPath());
                    repository.save({
                        descriptor: descriptor,
                        filename: descriptor.getName(),
                        mimetype: descriptor.getMimeType(),
                        data: content,
                        success: (descriptor) => {
                            resolve(descriptor);
                        },
                        error: (message) => {
                            reject(message);
                        }
                    });
                }
            });
        });
    }

    /*
     * load the right section with the navigator
     */
    loadNavigator(currentRepository, descriptor, successCallback, errorCallback) {
        this.removeNavigator();
        this.repositoryNavigator.find('.selectMessage').hide();
        var tree = this.repositoryNavigator.find('.repository-navigator-tree');
        tree.append('<div class="repository-navigator-status">' +
            '  <div class="folderName"></div>' +
            '  <div class="locationBar"></div>' +
            '</div>' +
            '<div class="navigator-column">' +
            '  <ul class="repository-navigator-container"></ul>' +
            '</div>');
        tree.show().css('visibility', 'hidden');
        this.loadFolder(currentRepository, descriptor, function() { // success
            tree.css('visibility', 'visible');
            if (typeof successCallback === "function") {
                successCallback();
            }
        }, function() {
            if (typeof errorCallback === "function") {
                errorCallback();
            }
        });
    }

    removeNavigator() {
        this.repositoryNavigator.find('.repository-navigator-commandbar .search').show();
        this.repositoryNavigator.find('.selectMessage').show();
        this.repositoryNavigator.find('.repository-navigator-tree').empty().hide();
    }

    async restoreFile(repository, descriptor) {
        return new Promise((resolve, reject) => {
            repository.restore({
                descriptor: descriptor,
                success: (descriptor) => {
                    resolve(descriptor);
                },
                error: reject
            });
        });
    }

    /*
     * Context Menu Navigation
     */
    showContextMenu(descriptors, ul, x, y, filter) {
        filter = filter || {};
        var that = this;
        var currentRepository = this.getCurrentRepository();
        var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(this.repositoryNavigator.find('.repository-navigator'));
        var menuUl = $('<ul></ul>').appendTo(menu);
        var commands = {};
        var additionalCommands = {};
        descriptors.forEach(function(descriptor) {
            $.extend(true, commands, that.getCommands(currentRepository, descriptor, false));
            $.extend(true, additionalCommands, that.getCommands(currentRepository, descriptor, true));
        });
        var newFilter = $.extend({}, filter);
        for (var command in commands) {
            if (typeof newFilter[command] === 'undefined') {
                newFilter[command] = true;
            }
        }
        var availableActions = {};
        var containsFolder = false;
        descriptors.forEach(function(descriptor) {
            $.extend(true, availableActions, descriptor.getActions(newFilter));
            if(descriptor.isFolder()) {
                containsFolder = true;
            }
        });
        if(containsFolder) {
            availableActions.copyTo = false;
        }
        
        if(this.currentSearchTerm && this.getSelectionItemsCount() === 1 && !descriptors[0].isFolder()) {
            availableActions.browseFolder = true;
        } else {
            delete availableActions.browseFolder;
        }

        var contextMenuMapping = {
            create: _('New'),
            browseFolder: _('Browse to Folder'),
            rename: _('Rename'),
            'delete': _('Delete'),
            upload: _('Upload'),
            download: _('Download'),
            versions: _('Versions'),
            moveTo: _('Move to'),
            copyTo: _('Copy to'),
            restore: _('Restore files'),
            promote: _('Promote place')
        };

        for (command in commands) {
            if ((that.readOnly && additionalCommands[command]) || !that.readOnly) {
                if ((command === 'upload' || command === 'download') && !this.allowUploadDownload) {
                    continue;
                }
                var menuLi = $('<li command="' + command + '">' + (contextMenuMapping[command] || command) + '</li>').appendTo(menuUl);
                if (availableActions[command] && currentRepository) {
                    menuLi.addClass('enabled');
                }
            }
        }

        var parent = menu.parent();
        var parentOffset = parent.offset();
        if (parentOffset.left + parent.width() < x + $(window).scrollLeft() + menu.width()) {
            x = parentOffset.left + parent.width() - menu.width() - $(window).scrollLeft() - 4;
        }
        if (parentOffset.top + parent.height() < y + $(window).scrollTop() + menu.height()) {
            y = parentOffset.top + parent.height() - menu.height() - $(window).scrollTop() - 4;
        }

        menu.css('left', x + 'px');
        menu.css('top', y + 'px');

        let maxWidth = parentOffset.left + parent.width() - (x + $(window).scrollLeft() + menu.width());
        this.getNewFileOptions($(this.repositoryNavigator).find('li[command=create]'), maxWidth);

        if (menuUl.children().length > 0) {
            menu.show().focus();
        } else {
            menu.hide();
        }

        menu.on('click', 'li.enabled[command]', function(e) {
            var $this = $(this);
            var command = $this.attr('command');
            if (typeof commands[command] !== "undefined") {
                commands[command](currentRepository, descriptors, ul);
            }
            if (command !== "create") {
                menu.remove();
            }
        });

        menu.bind('blur', function(e) {
            menu.remove();
        });
    }

    showErrorMessages(messages) {
        if(messages.length === 1) {
            return messages[0];
        }

        var errorMessage = "<ul>";
        messages.forEach(function(message) {
            errorMessage += "<li>" + message + "</li>";
        });
        errorMessage += "</ul>";
        return errorMessage;
    }

    /*
     * Context Menu Places
     */
    showPlacesContextMenu(descriptor, ul, x, y, callback) {
        var that = this;
        var currentRepository = this.getCurrentRepository();
        var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(this.repositoryNavigator.find('.repository-navigator'));
        var menuUl = $('<ul></ul>').appendTo(menu);

        var createContextMenu = function(availableActions, menu, menuUl) {
            var contextMenuMapping = {
                rename: _('Rename'),
                share: _('Share'),
                delete: _('Delete'),
                clone: _('Clone'),
                issues: _('Issues'),
                restore: _('Restore files'),
                promote: _('Promote place')
            };

            ['rename', 'share', 'clone', 'delete', 'issues', 'restore', 'promote'].forEach(function(command) {
                if (command !== 'issues' || (that.showIssueEditor && command === 'issues')){
                    var menuLi = $('<li command="' + command + '">' + contextMenuMapping[command] + '</li>').appendTo(menuUl);
                    if (availableActions[command] && currentRepository) {
                        menuLi.addClass('enabled');
                    }
                }
            });

            var parent = menu.parent();
            var parentOffset = parent.offset();
            if (parentOffset.left + parent.width() < x + $(window).scrollLeft() + menu.width()) {
                x = parentOffset.left + parent.width() - menu.width() - $(window).scrollLeft() - 4;
            }
            if (parentOffset.top + parent.height() < y + $(window).scrollTop() + menu.height()) {
                y = parentOffset.top + parent.height() - menu.height() - $(window).scrollTop() - 4;
            }

            menu.css('left', x + 'px');
            menu.css('top', y + 'px');

            menu.show().focus();

            menu.on('click', 'li.enabled[command]', function(e) {
                var $this = $(this);
                var command = $this.attr('command');
                if (typeof that.placesCommands[command] !== "undefined") {
                    that.placesCommands[command](currentRepository, [descriptor], ul);
                }
                menu.remove();
            });

            menu.bind('blur', function(e) {
                menu.remove();
            });
            callback();
        };

        if (typeof currentRepository.getCommands === "function") {
            currentRepository.getCommands(descriptor, function(commands) {
                createContextMenu(commands, menu, menuUl);
            });
        } else {
            createContextMenu({}, menu, menuUl);
        }
    }

    /*
     * Context Menu Places
     */
    showAddRepositoryContextMenu(ul, x, y) {
        var that = this;
        var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu add-repository"></div>').appendTo(this.repositoryNavigator.find('.repository-navigator'));
        var menuUl = $('<ul></ul>').appendTo(menu);

        providerRegistry.getAllNew(function(providers) {
            providers.forEach(function(provider) {
                var menuLi = $('<li class="enabled">' + provider.name + '</li>').appendTo(menuUl);
                menuLi.data('newInfo', provider);
            });
        });

        var parent = menu.parent();
        var parentOffset = parent.offset();
        x = ul.width() / 2;
        if (parentOffset.top + parent.height() < y + $(window).scrollTop() + menu.height()) {
            y = parentOffset.top + parent.height() - menu.height() - $(window).scrollTop() - 4;
        }

        menu.css('left', x + 'px');
        menu.css('bottom', (parentOffset.top + parent.height() - y) + 'px');
        menu.css('top', 'auto');
        menu.show().focus();

        menu.on('click', 'li.enabled', function(e) {
            var $this = $(this);
            var params = $this.data('newInfo');
            if (that.placesCommands.create !== undefined) {
                that.placesCommands.create(ul, params);
            }
            menu.remove();
        });

        menu.bind('blur', function(e) {
            menu.remove();
        });
    }

    getCommands(repository, descriptor, onlyNewActions) {
        var that = this;
        var o = {};

        var commandBar = this.repositoryNavigator.find('.repository-navigator-commandbar');
        var additionnalActions = this.contextualMenuCallback(repository, descriptor);
        if (additionnalActions && additionnalActions.length > 0) {
            additionnalActions.forEach(function(action) {
                o[action.label] = function(repository, descriptors, ul) {
                    if (action.callback) {
                        action.callback(repository, descriptors[0]);
                    }
                };
                if (!that.repositoryNavigator.find('.repository-navigator-commandbar > button[command="' + action.label + '"]')) {
                    $('<button command="' + action.label + '"><span class="icon"></span><span>' + action.label + '</span></button>').appendTo(commandBar);
                }
            });
        }
        if (onlyNewActions) {
            return o;
        } else {
            return $.extend({}, this.commands, o);
        }
    }

    /*
     * Refresh buttons on command bar
     */
    refreshCommandBar(descriptors, filter) {
        if (descriptors && descriptors.length > 0) {
            var commands = this.getCommands(this.getCurrentRepository(), descriptors[0], false);
            var newFilter = $.extend({}, filter);
            var multipleActions = {
                create: true,
                browseFolder: false,
                rename: false,
                'delete': true,
                copyTo: true,
                moveTo: true,
                upload: false,
                download: false,
                versions: false,
                restore: true,
                promote: true
            };

            if (descriptors.length > 1) {
                multipleActions.restore = false;
                multipleActions.promote = false;
                for (var i = 0; i < descriptors.length; i++) {
                    if(descriptors[i].isFolder()) {
                        multipleActions.copyTo = false;
                        break;
                    }
                }
            }

            var availableActions = (descriptors.length > 1 ? multipleActions : descriptors[0].getActions(newFilter));
            if(this.currentSearchTerm && this.getSelectionItemsCount() === 1 && !descriptors[0].isFolder()){
                availableActions.browseFolder = true;
            } else {
                delete availableActions.browseFolder;
            }
            
            for (var command in commands) {
                var btn = this.repositoryNavigator.find('.repository-navigator-commandbar > button[command="' + command + '"]');
                if (availableActions[command]) {
                    if (!btn.hasClass('enabled')) {
                        btn.addClass('enabled');
                    }
                } else {
                    if (btn.hasClass('enabled')) {
                        btn.removeClass('enabled');
                    }
                }
            }
        } else { // if no descriptor, desactivate the command bar
            this.repositoryNavigator.find('.repository-navigator-commandbar > button[command].enabled').removeClass('enabled');
        }
    }

    getDescriptors() {
        var column = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');
        var items = column.find('> ul > li.item.active');
        var descriptors = [];

        for (var inc = 0; inc < items.length; inc++) {
            var descriptor = $(items[inc]).data('descriptor');
            if (descriptor) {
                descriptors.push(descriptor);
            }
        }

        if (descriptors.length) {
            return descriptors;
        } else {
            return [column.data('descriptor')];
        }
    }

    setSelectedItems(begin, end) {
        var column = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');

        for (var i = begin; i <= end; i++) {
            column.find('> ul > li.item[data-pos="' + i + '"]').addClass('active');
        }
    }

    getSelectionItemsCount() {
        return $('.repository-navigator-tree ul li.item.active').length;
    }

    getFilter(descriptors) {
        var filter = null;
        if (descriptors.length > 1) {
            filter = {
                create: true,
                rename: false,
                versions: false
            };
        } else if (descriptors.length === 1) {
            if (descriptors[0].isFolder() && this.getSelectionItemsCount() === 0) {
                filter = {
                    'delete': false,
                    rename: false,
                    copyTo: false,
                    moveTo: false,
                    download: false
                };
            } else {
                filter = {
                    create: true
                };
                if (descriptors[0].isFolder()) {
                    filter.versions = false;
                    filter.upload = false;
                }
            }
        }
        filter.restore = this.getSelectionItemsCount() === 0;
        filter.promote = this.getSelectionItemsCount() === 0;
        return filter;
    }

    getLiByDescriptor(descriptor) {
        var column = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');
        var items = column.find('> ul > li.item');
        var li = null;

        for (var inc = 0; inc < items.length; inc++) {
            if ($(items[inc]).data('descriptor').attributes.id === descriptor.attributes.id) {
                li = $(items[inc]);
                break;
            }
        }

        return li;
    }

    /*
     * If there are 2 mimetypes, it's from a specific modeler
     * Else if there are more than 2 mimetypes it's from the DES launchpad
     */
    getMimetypeToRestore(mimetypes) {
        if(typeof mimetypes === "string") {
            // mimetype is a string in workbench
            return mimetypes;
        } else if(!mimetypes || mimetypes.length === 0 || mimetypes.length > 2) {
            return "";
        } else {
            return mimetypes[0];
        }
    }

    getCurrentRepository() {
        var li = this.repositoryNavigator.find('.navigator-repositories .repositories-column > li.active');
        return li.data('repository');
    }

    getCurrentFolderDescriptor() {
        var column = this.repositoryNavigator.find('.repository-navigator-center > .repository-navigator-tree > .navigator-column');
        return column.data('descriptor');
    }


    getPlacesRepositoriesAndDescriptors(repository, descriptors) {
        var places = [];
        var containsFolder = false;
        if(descriptors) {
            for(var i = 0; i < descriptors.length; i++) {
                if(descriptors[i].isFolder()) {
                    containsFolder = true;
                    break;
                }
            }
        }
        var items = this.repositoryNavigator.find('.navigator-repositories .repositories-column > li.item');
        for (i = 0; i < items.length; i++) {
            var placeRepository = $(items[i]).data('repository');
            var placeDescriptor = $(items[i]).data('descriptor');
            if(!containsFolder) {
                places.push({repository: placeRepository, descriptor: placeDescriptor});
            } else if(placeRepository.getName() === repository.getName()) {
                places.push({repository: placeRepository, descriptor: placeDescriptor});
                break;
            }
        }
        return places;
    }

    setSelection(selection) {
        var that = this;
        /*
         * we can use the sync method because all the providers are loaded before calling this function
         */
        var repository = providerRegistry.get(selection);
        if (!repository) {
            return;
        }

        var descriptor = repository.reviveDescriptor(selection);

        var repositoryLis = this.repositoryNavigator.find('.navigator-repositories .repositories-column > li[data-type="' + repository.type + '"]');
        var repositoryLi;
        repositoryLis.each(function(index, element) {
            var li = $(this);
            if (providerRegistry.get(li.data('descriptor')) === repository) {
                repositoryLi = li;
                return false; // break
            }
        });

        var filter;
        if (repositoryLi && !repositoryLi.hasClass('active')) {
            repositoryLi.siblings('.active').removeClass('active');
            repositoryLi.addClass('active');
            filter = {
                create: false,
                upload: false
            };
        }

        if (descriptor && typeof descriptor.isFolder === "function" && descriptor.isFolder()) {
            this.refreshCommandBar([descriptor], filter);
        } else {
            this.refreshCommandBar([descriptor]);
        }

        var loadNavigator = function(descriptor) {
            that.loadNavigator(repository, descriptor, function() {
                repositoryLi.removeClass('loading');
                if (typeof fileDescriptor !== "undefined") {
                    // select file
                    var li = that.repositoryNavigator.find('.navigator-column span.item-name > .name').filter(function() {
                        return $(this).text() === fileDescriptor.getName();
                    }).closest('li');
                    li.click();
                }
            }, function() {
                repositoryLi.removeClass('loading').removeClass('active');
            });
        };

        var cancel = function() {
            // if we are unable to get path from the file, we ignore the selection
            repositoryLi.removeClass('loading')
                .removeClass('active');
        };

        if (descriptor && repositoryLi) {
            // load the right view
            repositoryLi.addClass('loading');
            if (!descriptor.isFolder()) {
                var fileDescriptor = descriptor;
                repository.getFilePath({
                    descriptor: descriptor,
                    success: function(path) {
                        repository.getFolderDescriptorFromPath({
                            path: path,
                            success: function(folderDescriptor) {
                                loadNavigator(folderDescriptor);
                            },
                            error: function(message) {
                                cancel();
                            }
                        });
                    },
                    error: function(message) {
                        cancel();
                    }
                });
            } else {
                loadNavigator(descriptor);
            }
        }
    }

    open(descriptor) {
        var that = this;
        var btn = $('ul li button.open');
        btn.addClass('loading');
        var currentRepository = this.getCurrentRepository();
        descriptor = descriptor || this.getDescriptors()[0];
        if ($.isFunction(currentRepository.getPreOpenDisclaimer)) {
            var disclaimer = currentRepository.getPreOpenDisclaimer(descriptor);
            if (disclaimer) {
                this.repositoryNavigator.userMessage({
                    'title': _('Open'),
                    'message': disclaimer,
                    'buttons': [
                        {
                            'label': _('Ok'),
                            'callback': function() {
                                that.callbackOk(that.getCurrentRepository(), descriptor);
                            }
                        },
                        {
                            'label': _('Cancel'),
                            'callback': function() {
                                btn.removeClass('loading');
                                btn.removeAttr('disabled');
                            }
                        }
                    ]
                });
            } else {
                this.callbackOk(this.getCurrentRepository(), descriptor);
            }
        } else {
            this.callbackOk(this.getCurrentRepository(), descriptor);
        }
    }

    save(descriptor, name) {
        var that = this;
        var btn = $('ul li button.save');
        btn.addClass('loading');
        var currentRepository = this.getCurrentRepository();
        descriptor = descriptor || this.getDescriptors()[0];
        name = name || descriptor.getName();

        var preSaveDisclaimer = function() {
            if ($.isFunction(currentRepository.getPreSaveDisclaimer)) {
                var disclaimer = currentRepository.getPreSaveDisclaimer(descriptor);
                if (disclaimer) {
                    that.repositoryNavigator.userMessage({
                        'title': _('Save'),
                        'message': disclaimer,
                        'buttons': [
                            {
                                'label': _('Ok'),
                                'callback': function() {
                                    that.callbackOk(that.getCurrentRepository(), descriptor, name);
                                }
                            },
                            {
                                'label': _('Cancel'),
                                'callback': function() {
                                    btn.removeClass('loading');
                                    btn.removeAttr('disabled');
                                    $('.navigator-column > ul > li.active').removeClass('loading');
                                }
                            }
                        ]
                    });
                } else {
                    that.callbackOk(that.getCurrentRepository(), descriptor, name);
                }
            } else {
                that.callbackOk(that.getCurrentRepository(), descriptor, name);
            }
        };

        var nameExist = false;
        this.repositoryNavigator.find('.navigator-column > ul > li.item').each(function() {
            var $this = $(this);
            var des = $this.data('descriptor');
            if (des && des.attributes.name === name) {
                nameExist = true;
                return false;
            }
        });

        if (!descriptor.isFolder() || nameExist) {
            var displayName = (!descriptor.isFolder() ? descriptor.getName() : name);
            that.repositoryNavigator.userMessage({
                'title': _('Confirm Save As'),
                'message': _('%s already exists. Do you want to replace it?', displayName),
                buttons: [{
                    'label': _('Yes'),
                    'callback': function() {
                        preSaveDisclaimer();
                    }
                }, {
                    'label': _('No'),
                    'callback': function() {
                        btn.removeClass('loading');
                        btn.removeAttr('disabled');
                        $('.navigator-column > ul > li.active').removeClass('loading');
                    }
                }]
            });
        } else {
            preSaveDisclaimer();
        }
    }
}

jQueryWrapper('repositoryNavigator', RepositoryNavigator);


class NewFolderDescriptor {
    getName() {
        return _('New Folder');
    }

    isFolder() {
        return true;
    }

    getAttribute() {

    }

    getActions() {
        return new Object();
    }
}


class NewRepositoryDescriptor {
    getName() {
        return _('New Place');
    }

    isFolder() {
        return true;
    }
}
