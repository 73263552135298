import autosize from 'autosize';
import {jQueryFnWrapper} from './jqueryWrapper';
import i18n from './i18n.js';


var _ = i18n('repositoryNavigator');

// http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
var validateEmail = function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

/*
 *
 * Share Repository Plugin
 *
 */

class Share {
    constructor(element, {repository, callback} = {}) {
        this.$element = $(element);
        this.repository = repository;
        this.init(callback);
    }

    init(callback) {
        var that = this;

        var messageBackground = $('<div class="user-message-background"></div>');
        var container = $('<div class="user-message-container"></div>');
        var messageBox = $('<div class="user-message share">' +
            '  <div class="user-message-title">' + _('Who has access') + ' <button class="close">x</button></div>' +
            '  <div class="bottom-border global-access ' + (this.repository.type === "admin" ? "admin" : '') + '">' +
                '<div>' +
                    '<label>' + _("Global access") + '</label>' +
                '</div>' +
                '<div>' +
                    '<ul id="global-access"><li class="user-access">' +
                        '<span class="left">' + _("Everybody") + '</span>' +
                        '<span class="right"><span class="global-access-right" data-global-access="none">' + _("None") + '<i class="caret-down"></i></span></span>' +
                    '</li></ul>' +
                '</div>' +
            '  </div>' +
            '  <div class="user-message-message members read-only"><label>' + _("Members access") + '</label></div>' +
            '  <div class="user-message-message groups read-only"><label>' + _("Groups access") + '</label></div>' +
            '  <div class="user-message-buttons"></div>' +
            '</div>').appendTo(container);

        $('<button class="ok enabled">' + _('Ok') + '</button>').appendTo(messageBox.find('.user-message-buttons'));


        this.$element.on('click', '.user-message-buttons button.ok, .user-message-title button, .user-message-background', function() {
            container.remove();
            messageBackground.remove();
        });

        // set the position
        messageBackground.appendTo(this.$element);
        container.appendTo(this.$element).css({
            'visibility': 'visible'
        });

        var memberList = $('<ul class="users"></ul>').appendTo(messageBox.children('.user-message-message.members'));
        var inviteLi = $(('<li class="item invite">' +
            '  <button class="invite enabled">' + _('Invite') + '</button><span class="r-message">' + _('Only the owner can invite.') + '</span>' +
            '</li>').replace(/>\s+</g, '><')).appendTo(memberList);
        var groupList = $('<ul class="groups"></ul>').appendTo(messageBox.children('.user-message-message.groups'));
        var shareLi = $(('<li class="item invite">' +
            '  <button class="invite enabled">' + _('Share') + '</button><span class="r-message">' + _('Only the owner can invite.') + '</span>' +
            '</li>').replace(/>\s+</g, '><')).appendTo(groupList);

        var addLi = function(member) {
            var li = null;
            var spanEmail = '';

            if (member.type === "User") {
                li = $('<li class="item user" data-user="' + member.email + '"></li>').insertBefore(inviteLi);
                spanEmail = '<span class="user-email">(' + member.email + ')</span></span>';
            } else {
                li = $('<li class="item group" data-group="' + member.groupId + '"></li>').insertBefore(shareLi);
                spanEmail = '<span class="user-email"></span></span>';
            }
            var rights = '' + _('can view') + ' <i class="caret-down"></i>';
            var data = 'R';
            if (member.rights.owner) {
                rights = _('is owner');
                data = null;
            } else if (member.rights.write) {
                rights = _('can edit') + ' <i class="caret-down"></i>';
                data = 'RW';
            }
            var spanName = member.name ? '<span class="user-name">' + member.name + '</span> ' : '';
            if (!member.name) {
                spanName = "";
                spanEmail = '<span class="user-email">' + (member.type === "User" ? member.email : member.groupId) + '</span></span>';
            }

            li.append('<span class="left">' + spanName +
                spanEmail +
                '<span class="right"><span class="user-rights' + (!data ? ' owner' : '') + '"' + (data ? ' data-rights="' + data + '"' : '') + '>' + rights + '</span>' +
                (data ? '<span class="user-remove"><i class="remove"></i></span>' : '') + '</span>');
        };

        /*
         * load rights
         */
        var counter = 0;
        messageBox.find('.user-message-title').addClass('loading');
        this.repository.getUsers({
            success: function(response) {
                counter++;
                if (counter === 2) {
                    messageBox.find('.user-message-title').removeClass('loading');
                }
                response.data.forEach(function(user) {
                    addLi(user);
                });
            },
            error: function(message) {
                messageBox.remove();
                messageBackground.remove();
                counter++;
                if (counter === 2) {
                    messageBox.find('.user-message-title').removeClass('loading');
                }
                that.$element.userMessage({
                    'message': message
                });
            }
        });

        this.repository.getMemberRights({
            success: function(def) {
                counter++;
                if (counter === 2) {
                    messageBox.find('.user-message-title').removeClass('loading');
                }

                if (def.rights.owner || that.repository.type === "admin") {
                    messageBox.find('.user-message-message').removeClass('read-only').addClass('pam');
                } else {
                    messageBox.find('.user-message-message button.invite').removeClass('enabled');
                    messageBox.find('.r-message').show();
                }


                var label = _("None");
                if (def.publicRights === "read") {
                    label = _("Read");
                } else if (def.publicRights === "write") {
                    label = _("Write");
                }
                $('.global-access-right').html(label + '<i class="caret-down"></i>');
                $('.global-access-right').attr('data-global-access', def.publicRights);

                if (def.publicRights === "none" && that.repository.type !== "admin") {
                    $('div.global-access').hide();
                } else {
                    $('div.global-access').show();
                }
            },
            error: function(message) {
                counter++;
                container.remove();
                messageBackground.remove();
                if (counter === 2) {
                    messageBox.find('.user-message-title').removeClass('loading');
                }

                that.$element.userMessage({
                    'message': message
                });
            }
        });

        /*
         * remove user
         */
        memberList.on('click', 'li.item.user .user-remove', function(e) {
            var li = $(this).closest('li[data-user]');
            var email = li.attr('data-user');
            that.repository.unInviteUser({
                email: email,
                success: function() {
                    li.remove();
                },
                error: function(message) {
                    that.$element.userMessage({
                        'title': _('Uninvite Error'),
                        'message': message
                    });
                }
            });
        });

        /*
         * remove group
         */
        groupList.on('click', 'li.item.group .user-remove', function(e) {
            var li = $(this).closest('li[data-group]');
            var groupId = li.attr('data-group');
            that.repository.unInviteUser({
                group: groupId,
                success: function() {
                    li.remove();
                },
                error: function(message) {
                    that.$element.userMessage({
                        'title': _('Uninvite Error'),
                        'message': message
                    });
                }
            });
        });

        messageBox.on('click', '.admin ul#global-access > li.user-access .global-access-right', function() {
            var $this = $(this);
            var x = parseInt($this.offset().left);
            var y = parseInt($this.offset().top) + $this.height();

            var rights = $this.attr('data-global-access');
            var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(messageBox);
            var menuUl = $('<ul>' +
                '  <li command="read" class="enabled"><span class="role">' + _("Read") + '<span></li>' +
                '  <li command="write" class="enabled"><span class="role">' + _("Write") + '<span></li>' +
                '  <li command="none" class="enabled"><span class="role">' + _("None") + '<span></li>' +
                '</ul>').appendTo(menu);

            menuUl.find('li[command="' + rights + '"]').removeClass('enabled');

            menu.css('left', x + 'px');
            menu.css('top', y + 'px');

            menu.show().focus();

            menu.on('click', 'li.enabled[command]', function(e) {
                var $rightsLi = $(this);
                var command = $rightsLi.attr('command');

                that.repository.setRepositoryPublicRights({
                    rights: command,
                    success: function(data) {
                        $this.attr('data-global-access', command);
                        $this.html(_(command.charAt(0).toUpperCase() + command.slice(1)) + ' <i class="caret-down"></i>');
                        if (typeof callback === "function") {
                            callback(data);
                        }
                    },
                    error: function(message) {
                        that.$element.userMessage({
                            'title': _('Change public rights Error'),
                            'message': message
                        });
                    }
                });

                menu.remove();
            });

            menu.bind('blur', function(e) {
                menu.remove();
            });
        });

        /*
         * change user rights
         */
        messageBox.on('click', '.pam ul.users > li .user-rights[data-rights]', function() {
            var $this = $(this);
            var $li = $this.closest('li[data-user]');
            var x = parseInt($this.offset().left);
            var y = parseInt($this.offset().top) + $this.height();

            var rights = $this.attr('data-rights');
            var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(messageBox);
            var menuUl = $('<ul>' +
                '  <li command="R" class="enabled"><span class="role">' + _('can view') + '<span></li>' +
                '  <li command="RW" class="enabled"><span class="role">' + _('can edit') + '<span></li>' +
                '  <li command="PAM" class="enabled"><span class="role">' + _('is owner') + '<span></li>' +
                '</ul>').appendTo(menu);

            menuUl.find('li[command="' + rights + '"]').removeClass('enabled');

            menu.css('left', x + 'px');
            menu.css('top', y + 'px');

            menu.show().focus();

            menu.on('click', 'li.enabled[command]', function(e) {
                var $rightsLi = $(this);

                var command = $rightsLi.attr('command');
                $li.addClass('loading');
                var rights;
                if (command === 'R') {
                    rights = {
                        write: false
                    };
                } else if (command === 'RW') {
                    rights = {
                        write: true
                    };
                } else if (command === 'PAM') {
                    rights = {
                        owner: true,
                        write: true
                    };
                }
                that.repository.changeUserRights({
                    email: $li.attr('data-user'),
                    rights: rights,
                    success: function(user) {
                        $li.removeClass('loading');
                        $this.attr('data-rights', command);
                        var rights = _('can view') + ' <i class="caret-down"></i>';
                        if (user.rights.owner) {
                            rights = _('is owner');
                            // if an other user is the PAM, remove the editon on the list and update the rights to the old pam
                            $this.siblings('.user-remove').remove();
                            $this.closest('.pam').removeClass('pam')
                                .find('.owner').html(_('can edit'));
                            inviteLi.hide();
                        } else if (user.rights.write) {
                            rights = _('can edit') + ' <i class="caret-down"></i>';
                        }
                        $this.html(rights);
                    },
                    error: function(message) {
                        $li.removeClass('loading');
                        that.$element.userMessage({
                            'title': _('Change user rights Error'),
                            'message': message
                        });
                    }
                });

                menu.remove();
            });

            menu.bind('blur', function(e) {
                menu.remove();
            });
        });

        /*
         * change group rights
         */
        messageBox.on('click', '.pam ul.groups > li .user-rights[data-rights]', function() {
            var $this = $(this);
            var $li = $this.closest('li[data-group]');
            var x = parseInt($this.offset().left);
            var y = parseInt($this.offset().top) + $this.height();

            var rights = $this.attr('data-rights');
            var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(messageBox);
            var menuUl = $('<ul>' +
                '  <li command="R" class="enabled"><span class="role">' + _('can view') + '<span></li>' +
                '  <li command="RW" class="enabled"><span class="role">' + _('can edit') + '<span></li>' +
                '</ul>').appendTo(menu);

            menuUl.find('li[command="' + rights + '"]').removeClass('enabled');

            menu.css('left', x + 'px');
            menu.css('top', y + 'px');

            menu.show().focus();

            menu.on('click', 'li.enabled[command]', function(e) {
                var $rightsLi = $(this);

                var command = $rightsLi.attr('command');
                $li.addClass('loading');
                var rights;
                if (command === 'R') {
                    rights = {
                        write: false
                    };
                } else if (command === 'RW') {
                    rights = {
                        write: true
                    };
                }

                that.repository.changeUserRights({
                    groupId: $li.attr('data-group'),
                    rights: rights,
                    success: function(group) {
                        $li.removeClass('loading');
                        $this.attr('data-rights', command);
                        var rights = _('can view') + ' <i class="caret-down"></i>';
                        if (group.rights.write) {
                            rights = _('can edit') + ' <i class="caret-down"></i>';
                        }
                        $this.html(rights);
                    },
                    error: function(message) {
                        $li.removeClass('loading');
                        that.$element.userMessage({
                            'title': _('Change user rights Error'),
                            'message': message
                        });
                    }
                });

                menu.remove();
            });

            menu.bind('blur', function(e) {
                menu.remove();
            });
        });

        var options = {
            subject: _("You have been invited to %s", this.repository.getName()),
            message: _("Greetings,\n\nYou were invited to access the %s.\nTo access it, click the following URL:\n\n%s\n\nYou may need to create and validate an account with your email address if you don't already have an account.\n\nBest regards.", this.repository.getName(), window.location.origin)
        };
        /*
         * invite user
         */
        inviteLi.on('click', 'button.enabled', function(e) {
            container.remove();
            messageBackground.remove();
            options.invite = new Invite(that.repository);
            options.type = "User";
            that.$element.inviteModal(options);
        });

        shareLi.on('click', 'button.enabled', function(e) {
            container.remove();
            messageBackground.remove();
            options.invite = new Invite(that.repository);
            options.type = "Group";
            that.$element.inviteModal(options);
        });
    }
}
jQueryFnWrapper('shareModal', Share);

class User {
    constructor(user, name) {
        if (typeof user === "object") {
            this.load(user);
        } else {
            this.user = {
                email: user,
                name: name,
                rights: {
                    write: false
                }
            };
        }
    }

    load(obj) {
        this.user = obj;
    }

    getEmail() {
        return this.user.email;
    }

    getName() {
        return this.user.name;
    }

    canWrite(write) {
        if (write === undefined) {
            return this.user.rights.write;
        } else {
            this.user.rights.write = write;
        }
    }

    isOwner(owner) {
        if (owner === undefined) {
            return this.user.rights.owner;
        } else {
            this.user.rights.owner = owner;
        }
    }

    getRights() {
        return this.user.rights;
    }
}

class ExecutionUser extends User {
    constructor(user, name) {
        super(user, name);

        this.user.rights = 0;
    }

    setRights(right) {
        this.user.rights = right;
    }
}

class Group {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.members = [];
        this.right = false;
    }

    canWrite(write) {
        if (write === undefined) {
            return this.right;
        } else {
            this.right = write;
        }
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getMembers() {
        return this.members;
    }

    addMembers(members) {
        this.members = this.members.concat(members);
    }

    addMember(member) {
        this.members.push(member);
    }

    setRights(right) {
        this.right = right;
    }

    getRights() {
        return this.right;
    }
}

class Invite {
    constructor(repository) {
        this.repository = repository;
        this.users = [];
        this.groups = [];
        this.message = "";
        this.sendEmail = true;
        this.hasAccessToUsersList = true;
    }

    hasRightsManagementEnabled() {
        return true;
    }

    hasSendEmailForm() {
        return true;
    }

    getUsers(callback) {
        var that = this;
        if (typeof this.repository.getPotentialUsers === "function") {
            this.repository.getPotentialUsers({
                success: function(users) { // success
                    callback(users);
                },
                error: function() { // error
                    that.hasAccessToUsersList = false;
                    callback([]); // silently ignore errors
                }
            });
        } else {
            that.hasAccessToUsersList = false;
            callback([]);
        }
    }

    getGroups(callback) {
        this.repository.getGroups({
            success: function(groups) { // success
                callback(groups);
            },
            error: function(groups) { // error
                callback([]); // silently ignore errors
            }
        });
    }

    removeGroup(id) {
        var group = this._groupAlreadyAdded(id);
        if (group) {
            var index = this.groups.indexOf(group);
            this.groups.splice(index, 1);
            this._triggerEvent('removedGroup', group);
        }
    }

    addGroup(id, name) {
        var group;

        if (!this._groupAlreadyAdded(id)) {
            group = new Group(id, name);
            this._triggerEvent('addedGroup', group);
            this.groups.push(group);
        }

        return group;
    }

    _groupAlreadyAdded(id) {
        return this.groups.filter(function(group) {
            return group.getId() === id;
        })[0];
    }

    addUser(email, name) {
        var user;
        if (!this._getUserByEmail(email)) {
            user = new User(email, name);
            this.users.push(user);
            this._triggerEvent('addedUser', user);
        }
        return user;
    }

    _getUserByEmail(email) {
        return this.users.filter(function(user) {
            if (user.getEmail() && email) {
                return user.getEmail().toLowerCase() === email.toLowerCase();
            } else {
                return false;
            }
        })[0];
    }

    _getGroupById(id) {
        return this.groups.filter(function(group) {
            return group.getId() === id;
        })[0];
    }

    changeUserRights(email, rights) {
        /*jshint bitwise: false*/
        var user = this._getUserByEmail(email);
        if (user) {
            user.isOwner((rights & 4) === 4);
            user.canWrite((rights & 2) === 2);
            this._triggerEvent('changedUser', user);
        }
    }

    changeGroupRights(id, rights) {
        var group = this._getGroupById(id);
        if (group) {
            group.canWrite((rights & 2) === 2);
            this._triggerEvent('changedGroup', group);
        }
    }

    removeUser(email) {
        var user = this._getUserByEmail(email);
        if (user) {
            var index = this.users.indexOf(user);
            this.users.splice(index, 1);
            this._triggerEvent('removedUser', user);
        }
    }

    invite(success, error) {
        var that = this;

        var params = {
            sendEmail: this.hasAccessToUsersList && this.sendEmail,
            subject: this.hasAccessToUsersList && this.subject,
            message: this.hasAccessToUsersList && this.message,
            success: function(members) {
                if (!that.hasAccessToUsersList && that.sendEmail) {
                    // send email client side
                    document.location.href = "mailto:" + that.users.map(function(user) {
                        return user.getEmail();
                    }).join(';') + "?subject=" + encodeURIComponent(that.subject) + "&body=" + encodeURIComponent(that.message);
                }
                if (typeof success === "function") {
                    success(members);
                }
            },
            error: function(message) {
                if (typeof error === "function") {
                    error(message);
                }
            }
        };

        if (this.users.length > 0) {
            params.users = this.users.map(function(user) {
                return {
                    email: user.getEmail(),
                    write: user.canWrite()
                };
            });
            this.repository.invite(params);
        }

        if (this.groups.length > 0) {
            params.groups = this.groups.map(function(group) {
                return {
                    id: group.getId(),
                    write: group.canWrite()
                };
            });
            this.repository.invite(params);
        }
    }

    setSubject(subject) {
        this.subject = subject;
    }

    setMessage(msg) {
        this.message = msg;
    }

    setSendEmail(sendEmail) {
        this.sendEmail = sendEmail;
    }

    canAccessToUsersList() {
        return this.hasAccessToUsersList;
    }

    _triggerEvent(event) {
        var data = Array.prototype.slice.call(arguments, 1);
        $(this).triggerHandler(event, data);
    }
}

class GroupInvite extends Invite {
    constructor(id) {
        super();
        this.groupId = id;
    }

    hasRightsManagementEnabled() {
        return false;
    }

    getUsers(callback) {
        $.ajax({
            url: "/publicapi/user",
            type: "get",
            xhrFields: {
                "withCredentials": true
            },
            dataType: 'json',
            success: function(json) {
                callback(json.data);
            },
            error: function(data) {
                callback([]);
            }
        });
    }

    getGroups(callback) {
        $.ajax({
            url: "/publicapi/groups",
            type: "get",
            xhrFields: {
                "withCredentials": true
            },
            dataType: 'json',
            success: function(json) {
                callback(json.data);
            },
            error: function(message) {
                callback([]);
            }
        });
    }

    invite(success, error) {
        var that = this;

        this.users.forEach(function(user) {
            $.ajax({
                url: "/publicapi/groups/" + that.groupId + "/members?" + $.param({
                    user: user.getEmail(),
                    sendEmail: that.sendEmail,
                    subject: that.subject,
                    message: that.message
                }),
                type: "put",
                xhrFields: {
                    "withCredentials": true
                },
                dataType: 'json',
                success: function(json) {
                    if (typeof success === "function") {
                        success();
                    }
                },
                error: function(message) {
                    if (typeof error === "function") {
                        error(message);
                    }
                }
            });
        });

        this.groups.forEach(function(group) {
            $.ajax({
                url: "/publicapi/groups/" + that.groupId + "/members?" + $.param({
                    group: group.getId(),
                    sendEmail: that.sendEmail,
                    subject: that.subject,
                    message: that.message
                }),
                type: "put",
                xhrFields: {
                    "withCredentials": true
                },
                dataType: 'json',
                success: function(json) {
                    if (typeof success === "function") {
                        success();
                    }
                },
                error: function(message) {
                    if (typeof error === "function") {
                        error(message);
                    }
                }
            });
        });
    }
}

export class ExecutionInvite extends Invite {
    constructor(repository) {
        super(repository);
    }

    hasRightsManagementEnabled() {
        return true;
    }

    hasSendEmailForm() {
        return false;
    }

    addUser(email, name) {
        var user;
        if (!this._getUserByEmail(email)) {
            user = new ExecutionUser(email, name);
            this.users.push(user);
            this._triggerEvent('addedUser', user);
        }
        return user;
    }

    changeUserRights(email, rights) {
        /*jshint bitwise: false*/
        var user = this._getUserByEmail(email);
        if (user) {
            user.setRights(rights);
            this._triggerEvent('changedUser', user);
        }
    }

    changeGroupRights(id, rights) {
        var group = this._getGroupById(id);
        if (group) {
            group.setRights(rights);
            this._triggerEvent('changedGroup', group);
        }
    }

    invite(success, error) {
        var that = this;

        this.users.forEach(function(user) {
            that.repository.invite({
                email: user.getEmail(),
                rights: user.getRights(),
                success: success,
                error: error
            });
        });

        this.groups.forEach(function(group) {
            that.repository.invite({
                group: group.getId(),
                rights: group.getRights(),
                success: success,
                error: error
            });
        });
    }
}

class InviteModal {
    constructor(element, {type, invite, subject, message}={}) {
        this.$element = $(element);
        this.type = type;
        this.invite = invite;
        this.subject = subject;
        this.message = message;

        this.addClass();
        this.init();
    }

    addClass() {
        this.$element.addClass('invite-modal');
    }

    removeClass() {
        this.$element.removeClass('invite-modal');
    }

    init() {
        var that = this;

        var messageBackground = $('<div class="user-message-background"></div>');
        var container = $('<div class="user-message-container"></div>');
        var messageBox = $('<div class="user-message invite">' +
            '  <div class="user-message-title">' + (this.type === "User" ? _('Invite Users') : _('Invite Groups')) + ' <button class="close">x</button></div>' +
            '  <div class="user-message-message"></div>' +
            '  <div class="user-message-buttons"></div>' +
            '</div>').appendTo(container);
        var btns = messageBox.find('.user-message-buttons');

        if (this.invite.hasSendEmailForm()) {
            btns.append('<button class="next">' + _('Next') + '</button>');
        } else {
            btns.append('<button class="invite">' + (this.type === "User" ? _('Invite') : _('Share')) + '</button>');
        }
        btns.append('<button class="cancel enabled">' + _('Cancel') + '</button>');

        this.$element.off('.invite');

        /**
         * Cancel navigation
         */
        this.$element.on('click.invite', '.user-message-buttons .cancel, .user-message-title button, .user-message-background', function() {
            that.removeClass();
            container.remove();
            messageBackground.remove();
        });

        /**
         * Next navigation
         */
        this.$element.on('click.invite', '.user-message-buttons .next.enabled', function() {
            $(this).remove();
            btns.prepend('<button class="invite enabled">' + (that.type === "User" ? _('Invite') : _('Share')) + '</button>' +
                '<button class="back enabled">' + _('Back') + '</button>');
            messageBox.find('.user-message-message > .step1').hide();
            messageBox.find('.user-message-message > .step2').show();
            autosize(messageBox.find('.user-message-message > .step2 textarea').get(0));
        });

        /**
         * Back navigation
         */
        this.$element.on('click.invite', '.user-message-buttons .back', function() {
            $(this).remove();
            btns.find('.invite').remove();
            btns.prepend('<button class="next enabled">' + _('Next') + '</button>');
            messageBox.find('.user-message-message > .step1').show();
            messageBox.find('.user-message-message > .step2').hide();
        });

        /**
         * Click on the Invite button
         */
        this.$element.on('click.invite', '.user-message-buttons .invite', function() {
            var $this = $(this);
            $this.addClass('loading');
            var msg = messageBox.find('.step2 .email_message').val();
            that.invite.setMessage(msg);
            var subject = messageBox.find('.step2 .email_subject').val();
            that.invite.setSubject(subject);

            that.invite.invite(function() {
                $this.removeClass('loading');
                container.remove();
                messageBackground.remove();
            }, function(message) {
                $this.removeClass('loading');
                that.$element.userMessage({
                    'title': _('Invite Error'),
                    'message': message
                });
            });
        });

        // set the position
        messageBackground.appendTo(this.$element);
        container.appendTo(this.$element).css({
            'visibility': 'visible'
        });

        var step1 = $('<div class="step1"></div>').appendTo(messageBox.children('.user-message-message'));
        var step2 = $('<div class="step2"></div>').appendTo(messageBox.children('.user-message-message')).hide();

        /* Load first screen */
        var ul = $('<ul class="users"></ul>').appendTo(step1);
        var inputLi = $(('<li class="item invite">' +
            '  <span class="user-email"><input type="text" class="email" autocomplete="off" placeholder="' + (this.type === "User" ? _('Enter an email address') : _('Enter a group name')) + '" /></span>' +
            '  <button class="addUser enabled">' + _('Add') + '</button>' +
            '</li>').replace(/>\s+</g, '><')).appendTo(ul);


        /* Load second screen */
        step2.append('<div>' +
            '  <p>' + _('Modify the invite text that you would like to send:') + '</p>' +
            '  <div><input class="email_subject" type="text" /></div>' +
            '  <div>' +
            '    <textarea class="email_message"></textarea>' +
            '  </div>' +
            '  <div>' +
            '    <label><input class="email_confirmation" type="checkbox" checked/>' + _("Send invite email") + '</label>' +
            '  </div>' +
            '</div>');

        step2.find('.email_subject').val(this.subject);
        step2.find('.email_message').val(this.message);

        /**
         * Send email toggle button
         */
        step2.on('change', 'input[type=checkbox]', function() {
            var $this = $(this);
            if ($this.is(':checked')) {
                step2.find('.email_subject, .email_message').removeAttr('disabled');
                that.invite.setSendEmail(true);
            } else {
                step2.find('.email_subject, .email_message').attr('disabled', 'disabled');
                that.invite.setSendEmail(false);
            }
        });

        /**
         * Model Events add/change/remove user
         */
        $(that.invite).on('addedUser', function(e, user) {
            var li = $('<li class="item" data-user="' + user.getEmail() + '"></li>').insertBefore(inputLi);

            var left = $('<span class="left"></span>').appendTo(li);

            if (!validateEmail(user.getEmail())) {
                li.addClass('error');
                left.append('<span class="icon" title="' + _("The email address is invalid") + '"></span>');
            } else if (!user.getName() && that.invite.canAccessToUsersList()) {
                li.addClass('warning');
                left.append('<span class="icon" title="' + _("This user doesn't have a license") + '"></span>');
            }

            left.append('<span class="user-name">' + (user.getName() || '') + ' </span>');
            left.append('<span class="user-email">(' + user.getEmail() + ')</span>');

            if (that.invite.hasRightsManagementEnabled()) {
                var right = $('<span class="right"></span>').appendTo(li);
                right.append(that.getRightsSpan(user));
                right.append('<span class="user-remove"><i class="remove"></i></span>');
            }
            setNextBtnMode();
        });

        $(that.invite).on('removedUser', function(e, user) {
            var li = ul.find('li[data-user="' + user.getEmail() + '"]');
            li.remove();
            setNextBtnMode();
        });

        $(that.invite).on('changedUser', function(e, user) {
            var li = ul.find('li[data-user="' + user.getEmail() + '"]');
            li.find('.user-rights').replaceWith(that.getRightsSpan(user));
        });

        $(that.invite).on('addedGroup', function(e, group) {
            var li = $('<li class="item" data-group="' + group.getId() + '"></li>').insertBefore(inputLi);

            var left = $('<span class="left"></span>').appendTo(li);
            left.append('<span class="group-name">' + (group.getName() || '') + ' </span>');

            if (that.invite.hasRightsManagementEnabled()) {
                var right = $('<span class="right"></span>').appendTo(li);
                right.append(that.getRightsSpan(group));
                right.append('<span class="user-remove"><i class="remove"></i></span>');
            }
            setNextBtnMode();
        });

        $(that.invite).on('removedGroup', function(e, group) {
            var li = ul.find('li[data-group="' + group.getId() + '"]');
            li.remove();
            setNextBtnMode();
        });

        $(that.invite).on('changedGroup', function(e, group) {
            var li = ul.find('li[data-group="' + group.getId() + '"]');
            li.find('.user-rights').replaceWith(that.getRightsSpan(group));
        });

        var setNextBtnMode = function() {
            if (ul.children('li:not(.invite).error').length > 0 || ul.children('li:not(.invite)').length === 0) {
                if (that.invite.hasSendEmailForm()) {
                    that.$element.find('.user-message-buttons .next').removeClass('enabled');
                } else {
                    that.$element.find('.user-message-buttons .invite').removeClass('enabled');
                }
            } else {
                if (that.invite.hasSendEmailForm()) {
                    that.$element.find('.user-message-buttons .next').addClass('enabled');
                } else {
                    that.$element.find('.user-message-buttons .invite').addClass('enabled');
                }
            }
        };

        var currentRights = 1;

        /*
         * change user rights
         */
        if (this.$element.hasClass('invite-modal')) {
            messageBox.on('click', 'ul > li .user-rights[data-rights]', function() {
                var $this = $(this);
                if ($this.hasClass('repository-permissions')) {
                    return;
                }
                var $li = that.type === "User" ? $this.closest('li[data-user]') : $this.closest('li[data-group]');

                var x = parseInt($this.offset().left);
                var y = parseInt($this.offset().top) + $this.height();

                var rights = $this.attr('data-rights');
                var menu = $('<div tabindex="-1" class="repository-navigator-contextual-menu"></div>').appendTo(messageBox);
                var menuUl = $('<ul>' +
                    '  <li command="1" class="enabled"><span class="role">' + _('can view') + '<span></li>' +
                    '  <li command="3" class="enabled"><span class="role">' + _('can edit') + '<span></li>' +
                    '</ul>').appendTo(menu);

                menuUl.find('li[command="' + rights + '"]').removeClass('enabled');

                menu.css('left', x + 'px');
                menu.css('top', y + 'px');

                menu.show().focus();

                menu.on('click', 'li.enabled[command]', function(e) {
                    var $rightsLi = $(this);

                    var newRights = $rightsLi.attr('command');

                    currentRights = newRights;

                    if (that.type === "User") {
                        that.invite.changeUserRights($li.attr('data-user'), newRights);
                    } else {
                        that.invite.changeGroupRights($li.attr('data-group'), newRights);
                    }

                    menu.remove();
                });

                menu.bind('blur', function(e) {
                    menu.remove();
                });
            });
        } else {
            messageBox.on('click', 'ul > li .user-rights[data-rights] .repository-permissions', function() {
                $(this).toggleClass('highlight');
                var $this = $(this);
                var $li = that.type === "User" ? $this.closest('li[data-user]') : $this.closest('li[data-group]');
                var rights = $this.closest('.user-rights[data-rights]');

                var newRights = [];
                $li.find('.repository-permissions.highlight').each(function () {
                    newRights.push($(this).attr('data-right'));
                });

                var access = newRights.reduce(function (access, right) {
                    if (right === "admin") {
                        access |= 8;
                    }
                    if (right === "performer") {
                        access |= 4;
                    }
                    if (right === "deployer") {
                        access |= 2;
                    }
                    if (right === "builder") {
                        access |= 1;
                    }
                    return access;
                }, 0);

                if (that.type === "User") {
                    that.invite.changeUserRights($li.attr('data-user'), access);
                } else {
                    that.invite.changeGroupRights($li.attr('data-group'), access);
                }
                rights.attr('data-rights', access);
            });
        }

        var availableUsers = [];
        /**
         * Click on the Add User button
         */
        step1.on('click', '.addUser.enabled', function() {
            var input = $(this).siblings('.user-email').children('input');
            if (input.val() !== '') {
                if (that.type === "User") {
                    var registeredUser = availableUsers.filter(function(user) {
                        if(user.email && input.val()) {
                            return user.email.toLowerCase() === input.val().trim().toLowerCase();
                        } else {
                            return false;
                        }
                    })[0];
                    var user;
                    if (registeredUser !== undefined) {
                        user = that.invite.addUser(input.val().trim(), registeredUser.name);
                    } else {
                        user = that.invite.addUser(input.val().trim());
                    }
                    input.val('');
                    if (user) {
                        that.invite.changeUserRights(user.getEmail(), currentRights);
                    } else {
                        addMessage(_("This user is already in the list"));
                    }
                } else {
                    var existingGroups = availableUsers.filter(function(group) {
                        return input.val().trim().indexOf(group.externalId) > -1;
                    })[0];

                    if (existingGroups) {
                        var group = that.invite.addGroup(existingGroups.externalId, existingGroups.displayName);
                        if (!group) {
                            addMessage(_("This group is already in the list"));
                        }
                    }

                    input.val('');
                }
            }
        });

        /**
         * Remove a user from the list
         */
        if (this.type === "User") {
            step1.on('click', '.users li[data-user] .remove', function() {
                var li = $(this).closest('li[data-user]');
                var email = li.attr('data-user');
                that.invite.removeUser(email);
            });
        } else {
            step1.on('click', '.users li[data-group] .remove', function() {
                var li = $(this).closest('li[data-group]');
                var id = li.attr('data-group');
                that.invite.removeGroup(id);
            });
        }


        /**
         * Autocompletion
         */
        var $input = inputLi.find('input');
        if (this.type === "User") {
            that.invite.getUsers(function(users) {
                if (users.length && typeof $.fn.typeahead !== 'undefined') {
                    availableUsers = users;
                    $input.typeahead({
                        source: function(query, processCallback) {
                            processCallback(users.map(function(user) {
                                return {
                                    realName: user.name,
                                    name: user.name + ' (' + user.email + ')',
                                    email: user.email
                                };
                            }));
                        },
                        afterSelect: function(item) {
                            var user = that.invite.addUser(item.email, item.realName);
                            $input.val('');
                            if (!user) {
                                addMessage(_("This user is already in the list"));
                            }
                        },
                        minLength: 0,
                        items: 10,
                        autoSelect: true
                    });
                }
            });
        } else {
            that.invite.getGroups(function(groups) {
                if (groups.length && typeof $.fn.typeahead !== 'undefined') {
                    availableUsers = groups;
                    $input.typeahead({
                        source: function(query, processCallback) {
                            processCallback(groups.map(function(group) {
                                return {
                                    realName: group.displayName,
                                    name: group.displayName,
                                    id: group.externalId
                                };
                            }));
                        },
                        afterSelect: function(item) {
                            var user = that.invite.addGroup(item.id, item.realName);
                            $input.val('');
                            if (!user) {
                                addMessage(_("This group is already in the list"));
                            }
                        },
                        minLength: 0,
                        items: 10,
                        autoSelect: true
                    });
                }
            });
        }

        var clearMessage = function() {
            inputLi.removeClass('error');
            inputLi.children('.icon').remove();
        };

        var addMessage = function(msg) {
            inputLi.addClass('error');
            inputLi.prepend('<span class="icon" title="' + msg + '"></span>');
        };

        /**
         * bind input events
         */
        inputLi.on('keydown', 'input', function(e) {
            var $this = $(this);
            var btn = inputLi.find('.addUser');
            clearMessage();
            if (e.keyCode === 13 && $this.val() !== '') { // enter
                btn.click();
                $this.val('');
                return false;
            } else if (e.keyCode === 27) { // esc
                $this.val('');
                return false;
            }
            return true;
        });
    }

    getRightsSpan(member) {
        // read 1, write 2, owner 4
        var rights = 1;
        var message = _('can view') + ' <i class="caret-down"></i>';
        if (member.canWrite()) {
            rights += 2;
            message = _('can edit') + ' <i class="caret-down"></i>';
        }

        return $('<span class="user-rights" data-rights="' + rights + '">' + message + '</span>');
    }
}
jQueryFnWrapper('inviteModal', InviteModal);

class ExecutionInviteModal extends InviteModal {
    addClass() {
        this.$element.addClass('execution-invite-modal');
    }

    removeClass() {
        this.$element.removeClass('execution-invite-modal');
    }

    getRightsSpan(member) {
        var message = (
            '    <span class="fa fa-user repository-permissions noselect ' + (member.getRights() & 4 ? 'highlight' : '') + '" data-right="performer" title="' + _('Performer') + '"></span>' +
            '    <span class="fa fa-tachometer repository-permissions noselect ' + (member.getRights() & 8 ? 'highlight' : '') + '" data-right="admin" title="' + _('Admin') + '"></span>' +
            '    <span class="fa fa-cloud-upload repository-permissions noselect ' + (member.getRights() & 2 ? 'highlight' : '') + '" data-right="deployer" title="' + _('Deployer') + '"></span>' +
            '    <span class="fa fa-wrench repository-permissions noselect ' + (member.getRights() & 1 ? 'highlight' : '') + '" data-right="builder" title="' + _('Builder') + '"></span>' +
            '  </div>'
        );

        return $('<span class="user-rights" data-rights="' + member.getRights() + '">' + message + '</span>');
    }
}
jQueryFnWrapper('executionInviteModal', ExecutionInviteModal);

class GroupModal {
    constructor(element, {groupId, groupName, groupImported} = {}) {
        this.$element = $(element);
        this.groupId = groupId;
        this.groupName = groupName;
        this.imported = groupImported;
        this.init();
    }

    init() {
        var that = this;
        var messageBackground = $('<div class="user-message-background"></div>');
        var container = $('<div class="user-message-container"></div>');
        var messageBox = $('<div class="user-message share">' +
            '  <div class="user-message-title">' + _("Group") + ' <button class="close">x</button></div>' +
            '  <div class="user-message-message members"><label>' + _("Members") + '</label></div>' +
            '  <div class="user-message-message groups"><label>' + _("Included Groups") + '</label></div>' +
            '  <div class="user-message-buttons"></div>' +
            '</div>').appendTo(container);

        $('<button class="ok enabled">' + _("Ok") + '</button>').appendTo(messageBox.find('.user-message-buttons'));


        this.$element.on('click', '.user-message-buttons button.ok, .user-message-title button, .user-message-background', function() {
            container.remove();
            messageBackground.remove();
        });

        // set the position
        messageBackground.appendTo(this.$element);
        container.appendTo(this.$element).css({
            'visibility': 'visible'
        });

        var $userList = $('<ul class="users"></ul>').appendTo(messageBox.children('.user-message-message.members'));
        var $groupList = $('<ul class="groups"></ul>').appendTo(messageBox.children('.user-message-message.groups'));


        var addLi = function(member, imported) {
            var li = null;

            if (member.type === "User") {
                li = $('<li class="item" data-user="' + member.externalId + '"></li>').appendTo($userList);
            } else {
                li = $('<li class="item" data-group="' + member.externalId + '"></li>').appendTo($groupList);
            }
            var spanName = member.displayName ? '<span class="user-name">' + member.displayName + '</span> ' : '';
            var spanEmail = '<span class="user-email">(' + member.externalId + ')</span></span>';
            if (!member.displayName) {
                spanName = "";
                spanEmail = '<span class="user-email">' + member.externalId + '</span></span>';
            }

            var actions = '<span class="right"><span class="remove"><i class="remove"></i></span></span>';
            if (imported === 'true') {
                actions = '';
            }

            li.append('<span class="left">' + spanName +
                spanEmail +
                actions);
        };

        messageBox.find('.user-message-title').addClass('loading');
        $.ajax({
            url: "/publicapi/groups/" + this.groupId + "/members",
            type: "get",
            xhrFields: {
                "withCredentials": true
            },
            dataType: 'json',
            success: function(response) {
                messageBox.find('.user-message-title').removeClass('loading');
                response.data.forEach(function(member) {
                    addLi(member, that.imported);
                });
            },
            error: function(data) {
                messageBox.remove();
                messageBackground.remove();
                messageBox.find('.user-message-title').removeClass('loading');

                that.$element.userMessage({
                    'message': _("Unable to retrieve members from the group. Please try again later or contact us.")
                });
            }
        });

        if (that.imported === 'true') {
            return;
        }

        var inviteBtn = $(('<button class="invite enabled">' + _("Invite") + '</button>').replace(/>\s+</g, '><')).appendTo(messageBox.children('.user-message-message.members'));
        var shareBtn = $(('<button class="share-groups enabled">' + _("Share") + '</button>').replace(/>\s+</g, '><')).appendTo(messageBox.children('.user-message-message.groups'));

        this.$element.on('click', 'ul.users li.item .remove', function(e) {
            var li = $(this).closest('li[data-user]');
            var email = li.attr('data-user');

            $.ajax({
                url: "/publicapi/groups/" + that.groupId + "/members?" + $.param({
                    user: email
                }),
                type: "delete",
                xhrFields: {
                    "withCredentials": true
                },
                dataType: 'json',
                success: function(response) {
                    li.remove();
                },
                error: function(data) {
                    messageBox.remove();
                    messageBackground.remove();
                    messageBox.find('.user-message-title').removeClass('loading');

                    that.$element.userMessage({
                        'message': _("Unable to remove the member from the group. Please try again later or contact us.")
                    });
                }
            });
        });

        this.$element.on('click', 'ul.groups li.item .remove', function(e) {
            var li = $(this).closest('li[data-group]');
            var groupId = li.attr('data-group');

            $.ajax({
                url: "/publicapi/groups/" + that.groupId + "/members?" + $.param({
                    group: groupId
                }),
                type: "delete",
                xhrFields: {
                    "withCredentials": true
                },
                dataType: 'json',
                success: function(response) {
                    li.remove();
                },
                error: function(data) {
                    messageBox.remove();
                    messageBackground.remove();
                    messageBox.find('.user-message-title').removeClass('loading');

                    that.$element.userMessage({
                        'message': _("Unable to remove the group from the group. Please try again later or contact us.")
                    });
                }
            });
        });

        var options = {
            subject: _("You have been invited to join the group %s", this.groupName),
            message: _("Greetings,\n\nYou were invited to join the group %s.", this.groupName)
        };

        inviteBtn.on('click', function(e) {
            container.remove();
            messageBackground.remove();
            options.invite = new GroupInvite(that.groupId);
            options.type = "User";
            that.$element.inviteModal(options);
        });

        shareBtn.on('click', function(e) {
            container.remove();
            messageBackground.remove();
            options.invite = new GroupInvite(that.groupId);
            options.type = "Group";
            that.$element.inviteModal(options);
        });
    }
}
jQueryFnWrapper('groupModal', GroupModal);
