import {jQueryFnWrapper} from './jqueryWrapper';
import i18n from './i18n.js';
var _ = i18n('repositoryNavigator');

/*
 *
 * User Message Plugin
 *
 */
export class UserMessage {
    constructor(element, {message, title, buttons, width} = {}) {
        this.$element = $(element);
        this.message = message;
        this.title = title;
        this.buttons = buttons;
        this.width = width;
        this.init();
    }

    init() {
        var messageBackground = $('<div class="user-message-background"></div>');
        var message = this.message;
        if (!message) {
            message = _('An error occured, please try again later.');
        }
        var title = this.title;
        if (!title) {
            title = 'Error';
        }
        var container = $('<div class="user-message-container"></div>');
        var messageBox = $('<div class="user-message">' +
            '  <div class="user-message-title">' + title + '</div>' +
            '  <div class="user-message-message">' + message + '</div>' +
            '  <div class="user-message-buttons"></div>' +
            '</div>').appendTo(container);
        var buttons = this.buttons;
        if (buttons) {
            for (var i = 0; i < buttons.length; i++) {
                $('<button class="enabled">' + buttons[i].label + '</button>')
                    .appendTo(messageBox.find('.user-message-buttons'))
                    .click($.proxy(function() {
                        if ($.isFunction(this.callback)) {
                            this.callback.call(this, this.label);
                        }
                        container.remove();
                        messageBackground.remove();
                    }, buttons[i]));
            }
        } else {
            $('<button class="enabled">' + _('Ok') + '</button>')
                .appendTo(messageBox.find('.user-message-buttons')).click(function() {
                    container.remove();
                    messageBackground.remove();
                });
        }
        if (this.width) {
            messageBox.css('width', this.width);
        }

        messageBackground.appendTo(this.$element);
        container.appendTo(this.$element).css({
            'visibility': 'visible'
        });
    }

}

jQueryFnWrapper('userMessage', UserMessage);
