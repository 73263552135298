import {jQueryFnWrapper} from './jqueryWrapper';
import {dateToFullString, layoutColumns} from './utils.js';
import i18n from './i18n.js';

var _ = i18n('repositoryNavigator');


/*
 *
 * Versions Modal Plugin
 *
 */

class Versions {
    constructor(element, {openCallback, descriptor, repository} = {}) {
        this.$element = $(element);
        this.openCallback = openCallback;
        this.descriptor = descriptor;
        this.repository = repository;
        this.init();
    }

    init() {
        var that = this;
        var descriptor = this.descriptor;
        var repository = this.repository;
        var $element = this.$element;
        var messageBackground = $('<div class="user-message-background"></div>');
        var container = $('<div class="user-message-container"></div>');
        var messageBox = $('<div class="user-message versionModal">' +
            '  <div class="user-message-title loading">' + _('Version History of %s', descriptor.getName()) + ' <button class="close">x</button></div>' +
            '  <div class="user-message-message"></div>' +
            '  <div class="user-message-buttons"></div>' +
            '</div>').appendTo(container);

        $('<button class="open">' + _('Open') + '</button>').appendTo(messageBox.find('.user-message-buttons'));
        $('<button class="enabled cancel">' + _('Cancel') + '</button>').appendTo(messageBox.find('.user-message-buttons'));

        $element.off('.versions');

        $element.on('click.versions', '.user-message-buttons button.enabled.cancel, .user-message-title button, .user-message-background', function() {
            container.remove();
            messageBackground.remove();
        });

        $element.on('click.versions', '.user-message-buttons button.open', function() {
            var version = messageBox.find('ul > li.item.active').data('descriptor');
            container.remove();
            messageBackground.remove();
            if (that.openCallback) {
                that.openCallback(version);
            }
        });

        // set the position
        messageBackground.appendTo(this.$element);
        container.appendTo(this.$element);

        repository.getVersions({
            descriptor: descriptor,
            success: function(versions) {
                messageBox.find('.user-message-title').removeClass('loading');
                versions.sort(function(a, b) {
                    return b.getUpdated() - a.getUpdated();
                }).forEach(function(version) {
                    addVersion(version);
                });
                if (versions.length === 0) {
                    addNoVersionMessage();
                }
                layoutColumns(ul, 0);
            },
            error: function(message) {
                container.remove();
                messageBackground.remove();
                $element.userMessage({
                    'message': message
                });
            }
        });

        var extraHeaders = [];
        if (typeof(repository.getExtraColumns) === 'function') {
            extraHeaders = repository.getExtraColumns();
        }

        var ul = $('<ul class="versions"></ul>').appendTo(messageBox.children('.user-message-message'));
        var html = ('<li class="header">' +
            '<div class="details">' +
            '<span class="updated">' + _('Last Updated') + '</span>' +
            '<span class="updater">' + _('Updated by') + '</span>');

        for (var i = 0; i < extraHeaders.length; i++) {
            html += '<span class="extraColumn-' + i + '" title="' + _(extraHeaders[i]) + '" data-toggle="tooltip">' + _(extraHeaders[i]) + '</span>';
        }

        html += "</div></li>";
        ul.append(html);

        var inc = 0;
        var addVersion = function(versionDescriptor) {
            var li = $('<li class="item"><div class="details"></div></li>').appendTo(ul);
            if (inc === 0) {
                li.addClass('current');
                ++inc;
            }
            var details = li.children('.details');
            details.append('<span class="updated" title="' + dateToFullString(versionDescriptor.getUpdated()) + '" data-toggle="tooltip">' + dateToFullString(versionDescriptor.getUpdated()) + '</span>' +
                '<span class="updater" title="' + versionDescriptor.getUpdater() + '" data-toggle="tooltip">' + versionDescriptor.getUpdater() + '</span>');

            for (var i = 0; i < extraHeaders.length; i++) {
                var value = repository.getExtraColumnValue(extraHeaders[i], versionDescriptor) || '-';
                details.append('<span class="extraColumn-' + i + '" title="' + value + '" data-toggle="tooltip">' + value + '</span>');
            }

            li.data('descriptor', versionDescriptor);
        };

        var addNoVersionMessage = function() {
            ul.append('<li class="noVersion">' + _('There are no previous versions available.') + '</li>');
        };

        var openBtn = messageBox.find('button.open');
        ul.on('click', 'li.item', function() {
            var $this = $(this);
            $this.siblings('.active').removeClass('active');
            $this.toggleClass('active');
            if ($this.hasClass('active')) {
                openBtn.addClass('enabled');
            } else {
                openBtn.removeClass('enabled');
            }
        });

        // Call the openCallback when double click on item (#28)
        ul.on('dblclick', 'li.item', function() {
            var $this = $(this);
            var version = $this.data('descriptor');
            container.remove();
            messageBackground.remove();
            if (that.openCallback) {
                that.openCallback(version);
            }
        });
    }
}

jQueryFnWrapper('versionsModal', Versions);
